import React, { useEffect, useState } from 'react'
import { Banner } from '../Banner'
import TrustedOrganization from '../TrustedOrganization'
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { GoPlus } from "react-icons/go";
import { AccordionButton } from 'react-bootstrap';
import { GoHorizontalRule } from "react-icons/go";
import Aos from 'aos';
import FooterBrands from "../FooterBrands";
import { GetStarted } from '../FooterSection/GetStarted';
import { Helmet } from 'react-helmet';

export const Talent_Aquasition = ({ adata }) => {
    const TalentAquasition = adata;

    useEffect(() => {
        Aos.init({ duration: 2000 })
    })

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1269);
        };

        window.addEventListener('resize', updateScreenSize);

        updateScreenSize();

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>Phenom eCloud Talent Acquisition Services</title>
                <meta name="description" content="Discover our expert talent acquisition services for access to top-tier technology and a streamlined hiring process. " />
            </Helmet>
            <Banner />
            <TrustedOrganization />

            <div className="TalentAquasition">
                {
                    TalentAquasition.map((item, index) => {
                        const { className, ContentData, CardSection } = item;
                        return (
                            className === "FlexContent" && (
                                <div className={className} key={index}>
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { containerClass, className, Grid } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    <div className={`${containerClass ? containerClass : ''}`}>
                                                        {
                                                            Grid.map((item, index) => {
                                                                const { className, TextData } = item;
                                                                return (
                                                                    <div className={`${className}`} key={index}>
                                                                        {
                                                                            TextData.map((item, index) => {
                                                                                const { HeadinClass, className, DropDown, IdName, Name, Heading, iconImg, Img, BtnUrl, mainGridClass } = item;
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        {
                                                                                            HeadinClass ? (
                                                                                                <div className={`${HeadinClass ? HeadinClass : ''}`} id={IdName} >
                                                                                                    {
                                                                                                        iconImg && (
                                                                                                            <div className="ImgSection">
                                                                                                                <img className='small-icon me-2' src={require(`../../image/HCM Coe/Talent Acquisition/${iconImg}`)} alt="" />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                    {BtnUrl ? (
                                                                                                        <Link to={BtnUrl} className={className}>{Name}</Link>
                                                                                                    ) : (
                                                                                                        <p className={className}>{Name}</p>
                                                                                                    )}

                                                                                                </div>
                                                                                            ) : (
                                                                                                < >
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            mainGridClass ? (
                                                                                                <div className={`${mainGridClass ? mainGridClass : ''}`} id={IdName} >
                                                                                                    <p className={className}>{Name}</p>
                                                                                                    <p className='fw-bolder h4 m-0'>{Heading}</p>
                                                                                                    {
                                                                                                        DropDown && (
                                                                                                            <Accordion className='mb-5 mt-2 Ec-fix-Height' >
                                                                                                                {DropDown.map((item, index) => {
                                                                                                                    const { Name, DescriptionData } = item;
                                                                                                                    return (
                                                                                                                        <Accordion.Item eventKey={index.toString()} className='m-2 border-0' key={index} data-aos="zoom-in">
                                                                                                                            <AccordionButton className='Dropdown-Bg-Color'
                                                                                                                                onClick={() => handleToggle(index.toString())}
                                                                                                                                aria-expanded={expandedItem === index.toString()}
                                                                                                                            >
                                                                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                                                                    <span className='fw-bolder sm-font-size'>{Name}</span>
                                                                                                                                    {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                                                                                </div>
                                                                                                                            </AccordionButton>
                                                                                                                            {DescriptionData.map((descItem, descIndex) => {
                                                                                                                                const { Heading, Name } = descItem;
                                                                                                                                return (
                                                                                                                                    <Accordion.Body key={descIndex} className='py-1'>
                                                                                                                                        <p className='fw-bolder sm-font-size'>{Heading}</p>
                                                                                                                                        <p className='sm-font-size'>{Name}</p>
                                                                                                                                    </Accordion.Body>
                                                                                                                                );
                                                                                                                            })}
                                                                                                                        </Accordion.Item>
                                                                                                                    );
                                                                                                                })}
                                                                                                            </Accordion>)
                                                                                                    }

                                                                                                    {Img && (
                                                                                                        <div className=" d-flex justify-content-center" data-aos="zoom-in">
                                                                                                            <img className='img-size d-flex justify-content-center' src={require(`../../image/HCM Coe/Talent Acquisition/${Img}`)} alt="" />
                                                                                                        </div>)}


                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )
                                                                                        }

                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }))
                                    }

                                </div>
                            )
                        )
                    })
                }
               {
    TalentAquasition.map((item, index) => {
        if (item.id === 3 || item.id === 4) {
            return (
                <div className={`row d-flex justify-content-around py-4 mb-3 ${item.title}`} key={index}>
                    <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_remove padding_fix">
                        <div className="d-flex flex-column align-items-center justify-content-around mt-3">
                            <div className="infotexts">
                                <div className={`row ${item.title}`}>
                                    <div className="col-lg-8 col-md-9 col-sm-8 col-12 d-flex flex-column justify-content-center" style={{ padding: '16px' }}>
                                        {item.infotext.map((paragraph, index) => (
                                            <p className="fw-bold normal-text-font mb-3" key={index}>{paragraph}</p>
                                        ))}
                                        {item.btn && (
                                            <div>
                                                <a className="btn HoverBtn button-hover overlaybtn text-white normal-text-font" href={item.btnLink}>
                                                    {item.btn}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-3 col-sm-4 col-12 img-h">
                                        <img 
                                            src={require(`../../image/HCM Coe/Talent/${item.bannerImage}.png`)} 
                                            alt="" 
                                            className="img-fluid img" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null; // Explicit return for non-matching items
    })
}

                {
                    TalentAquasition.map((item, index) => {
                        const { className, HeadingData, CardSection } = item;
                        return (
                            className === "OurServices" && (
                                <div className={`${className}`} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid, CardSection } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-center align-items-center row px-4 padding_remove">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className} dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            CardSection && (
                                                                CardSection.map((item, index) => {
                                                                    const { className, CardData } = item;
                                                                    return (
                                                                        <div className={className} key={index}>
                                                                            <div className="card-section-content row px-5 justify-content-center text-center padding_remove inside-border-padding-remove">
                                                                                {
                                                                                    CardData.map((item, index) => {
                                                                                        const { GridName, className, HeadingClassName, ImgName, Heading, Name, UrlName, Url, DescriptionName, popUp, LastCard } = item;
                                                                                        return (
                                                                                            <div className={GridName} key={index} data-aos={`${isSmallScreen ? 'fade-up' : popUp}`}>
                                                                                                {LastCard !== "yes" ? (<div className={`${className}`}>
                                                                                                    <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                                                                        {
                                                                                                            Heading && (
                                                                                                                <p className={`fw-bolder card-heading-size ${HeadingClassName ? HeadingClassName : ''} ${HeadingClassName ? "text-center" : 'text-start'}`}>{Heading}</p>
                                                                                                            )
                                                                                                        }
                                                                                                        {ImgName && (
                                                                                                            <img className='icon-size icon-box mix-blend' src={require(`../../image/HCM Coe/Talent Acquisition/${ImgName}`)} alt="" />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="card-body px-1 d-flex flex-column text-start justify-content-center">

                                                                                                        {
                                                                                                            Name && (
                                                                                                                <p className={`Description m-0 ${HeadingClassName ? "text-center" : 'text-start'}`}>{Name}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        Url && (
                                                                                                            <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                                                                <Link to={Url} className='text-start text-decoration-none UrlText px-2' href="">{UrlName}</Link>
                                                                                                                <Link className='btn card-btn-color' to={Url}><i className="fa-solid fa-arrow-right px-2 "></i></Link>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                </div>) : (<div className={`${className}`}>
                                                                                                    <div className="cardImg w-50 text-start d-flex justify-content-between align-items-center">
                                                                                                        {
                                                                                                            Heading && (
                                                                                                                <p className='fw-bolder card-heading-size'>{Heading}</p>
                                                                                                            )
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div className="card-body px-1 d-flex flex-column text-start justify-content-center pb-0 pt-0">
                                                                                                        <div className="row">
                                                                                                            <div className="row last-card-img-reverse">
                                                                                                                <div className="col-md-9 d-flex flex-column justify-content-center">
                                                                                                                    {
                                                                                                                        DescriptionName && (
                                                                                                                            DescriptionName.map((item, index) => {
                                                                                                                                const { className, BoldName, Name, BtnName, DescriptionName } = item;
                                                                                                                                return (
                                                                                                                                    <React.Fragment key={index}>
                                                                                                                                        {
                                                                                                                                            BoldName && (
                                                                                                                                                <p className={className}><strong>{BoldName}</strong> {Name}</p>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </React.Fragment>

                                                                                                                                )
                                                                                                                            })
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="col-md-3">
                                                                                                                    {ImgName && (
                                                                                                                        <div className="d-flex justify-content-center">
                                                                                                                            <img className='last-card-iconimg float-end mix-blend' src={require(`../../image/HCM Coe/Talent Acquisition/${ImgName}`)} alt="" />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-12">
                                                                                                                {
                                                                                                                    DescriptionName && (
                                                                                                                        DescriptionName.map((item, index) => {
                                                                                                                            const { className, BoldName, Url, BtnName, DescriptionName } = item;
                                                                                                                            return (
                                                                                                                                <React.Fragment key={index}>
                                                                                                                                    {
                                                                                                                                        BtnName && (
                                                                                                                                            <>
                                                                                                                                                {
                                                                                                                                                    DescriptionName && (
                                                                                                                                                        <p className={className}><strong>{BoldName}</strong> {DescriptionName}</p>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                {
                                                                                                                                                    Url && (
                                                                                                                                                        <Link to={Url} className='btn HoverBtn text-white Description-Fontsize'> {BtnName}</Link>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </React.Fragment>

                                                                                                                            )
                                                                                                                        })
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>)}
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )

                                                        }
                                                    </div>
                                                )
                                            })
                                        )
                                    }

                                </div>
                            )
                        )
                    })
                }
            </div>
            <FooterBrands />
            <GetStarted />
        </>
    )
}
