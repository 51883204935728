
import React, { useEffect, useState } from "react";
import homedata from "../../data/Home/agile.json";
import axios from "axios";
import defaultImage from '../../image/Default.jpg'
import TrustedOrganization from "../TrustedOrganization";
import { Guides } from "./Guides";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";


const Agile = () => {

    const [datas, setData] = useState([]);
    const [chunkedData, setChunkedData] = useState([]);
    const apiUrl = 'api/content/api/v2/blog-posts';
    // let apiUrl;
    const token = 'pat-na1-e55107ff-c943-4afe-80e9-edd023f84616';
    const fullUrl = window.location.href;
    // console.log(fullUrl);

    // Use apiUrl for your API requests
    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // console.log("Response Status:", response.status);
            // console.log("Response Headers:", response.headers);
            // console.log("Full API Response Data:", response.data);

            // Check if we received expected data
            if (response.data && response.data.objects) {
                const filteredData = response.data.objects.filter(item => item.current_state === 'PUBLISHED');
                // console.log("Filtered Data:", filteredData);
                setData(filteredData);
            } else {
                console.error("No data objects found in response.");
            }
        } catch (error) {
            console.error("Error fetching data:", error.response ? error.response.data : error.message);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        const calculateChunkSize = () => {
            if (window.innerWidth < 576) {
                return 1;
            } else if (window.innerWidth >= 576 && window.innerWidth <= 992) {
                return 2;
            } else {
                return 3;
            }
        };

        const calculateChunkedData = (data) => {
            const chunkedData = [];
            const chunkSize = calculateChunkSize();
            for (let i = 0; i < data.length; i += chunkSize) {
                chunkedData.push(data.slice(i, i + chunkSize));
            }
            return chunkedData;
        };

        setChunkedData(calculateChunkedData(datas));

        const handleResize = () => {
            setChunkedData(calculateChunkedData(datas));
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [datas]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
      <div className="Agile container-flude">
        <TrustedOrganization />
        <div className=" AgileContent p-5 ">
          <div className="row d-flex justify-content-around">
            <div className="YourCloudSection">
              <div className="CloudContent text-white">
                {homedata.map((item, index) => {
                  const { id, title, disc, tabledata } = item;
                  return (
                    id === 2 && (
                      <div
                        className="MainCardBox p-5 d-flex align-items-center text-center row justify-content-center"
                        key={index}
                      >
                        <div className="col-md-7">
                          <h3>{title}</h3>
                        </div>
                        <div className="col-md-9">
                          {disc.map((item, index) => (
                            <p
                              className="Description"
                              dangerouslySetInnerHTML={{ __html: item.text }}
                            ></p>
                          ))}
                          {/* */}
                        </div>

                        <div className="row justify-content-center mt-4 CardBodySection">
                          {tabledata.sort((a, b) => a.head.localeCompare(b.head)) .map((item, index) => {
                            const { id, image, head, Url } = item;
                            return (
                              <div className="col-md-3 mb-4" key={id}>
                                <div className="cardSection">
                                  {Url !== "" ? (
                                    <Link to={Url}>
                                      <div className="card">
                                        <LazyLoad offset={100}>
                                          <img
                                            className="agile-image"
                                            src={require(`../../image/Agile/${image}`)}
                                            alt=""
                                          />
                                        </LazyLoad>
                                        <div className="card-body">
                                          <p>{head}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  ) : (
                                    <p>
                                      <div className="card">
                                        <img
                                          src={require(`../../image/Agile/${image}`)}
                                          alt=""
                                        />
                                        <div className="card-body">
                                          <p>{head}</p>
                                        </div>
                                      </div>
                                    </p>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
            <div className="MainContent mt-0 d-flex flex-column justify-content-center align-items-center">
              <a
                href="https://meetings.hubspot.com/natasha-ramdial-roopnarine"
                target="_blank"
                onClick={() => {
                  scrollToTop();
                }}
                className="btn btn-dark read"
              >
                Lets Talk About Our Services
              </a>
              <div className="AgileBorder d-flex flex-column align-items-center p-5 mt-4 mb-5 w-100">
                <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                  <div className="w-100">
                    <div className=" w-100 d-flex justify-content-around">
                      <h3>Latest News & Insights</h3>
                    </div>
                    <div
                      id="carouselExampleControls"
                      className="carousel slide d-flex flex-column align-items-center mt-4"
                      data-bs-interval="false"
                    >
                      <div className="carousel-inner">
                        {/* {chunkedData.map((chunk, index) => {
                                                return (
                                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                                        <div className="row">
                                                            {chunk.map((carouselData, idx) => {
                                                                const { html_title, featured_image, url } = carouselData;
                                                                return (
                                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12" key={idx}>
                                                                        <div className="card mainCardSection" style={{ height: '332px', width: '100%', overflow: 'hidden', borderRadius: '30px' }}>
                                                                            <div className="h-75 overflow-hidden">
                                                                                <img className="card-img-top h-100 hover" src={featured_image !== null && featured_image !== "" ? featured_image : defaultImage} alt="Featured" />
                                                                            </div>
                                                                            <div className="card-body CardContentSection">

                                                                                <div className="Heading">
                                                                                    <h6 className="card-text">{html_title}</h6>
                                                                                </div>
                                                                                <div className=" mt-2">
                                                                                    <a href="/" className="btnSlide text-decoration-none small" >Read Blog <i className="fa-solid fa-arrow-right"></i></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>)
                                            })} */}
                        <div className="row static_blog_cards justify-content-around">
                          <div className="col-lg-4 col-md-4 col-sm-6 col-12 card brd p-0">
                            <img
                              src={require(`../../image/Blogs/7-signs-you-need-help-with.webp`)}
                              className="card-img-top"
                              alt="..."
                            />
                            <div className="card-body  ">
                              <h5 className="card-title fw-bold">
                                7 Signs you need help with your Integrations
                              </h5>
                              <div className="slidebtn text-start fw-bold d-flex align-items-center" style={{ color: "#EF571A" }}>
                                Read Blog
                                <a href="https://blog.phenomecloud.com/7-signs-you-need-help-with-your-integration"  target="_blank"  className="btnSlide">
                                 <i className="fa-solid fa-arrow-right " style={{ color: "#EF571A" }}></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6 col-12 card brd p-0">
                            <img
                              src={require(`../../image/Blogs/How-to-Build-Your-People-Analytics.webp`)}
                              className="card-img-top"
                              alt="..."
                            />
                            <div className="card-body  ">
                              <h5 className="card-title fw-bold">
                                How to Build Your People Analytics on a Strong
                                Data Foundation
                              </h5>
                              <div className="slidebtn text-start fw-bold d-flex align-items-center" style={{ color: "#EF571A" }}>
                                Read Blog
                                <a href="https://blog.phenomecloud.com/how-to-build-your-people-analytics-on-a-strong-data-foundation"  target="_blank"  className="btnSlide">
                                 <i className="fa-solid fa-arrow-right " style={{ color: "#EF571A" }}></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6 col-12 card brd p-0">
                            <img
                              src={require(`../../image/Blogs/How-Governance-Can.jpg`)}
                              className="card-img-top"
                              alt="..."
                            />
                            <div className="card-body  ">
                              <h5 className="card-title fw-bold">
                                How Governance Can Help Align Learning and
                                Development with Business Strategy
                              </h5>
                              <div className="slidebtn text-start fw-bold d-flex align-items-center" style={{ color: "#EF571A" }}>
                                Read Blog
                                <a href="https://blog.phenomecloud.com/how-governance-can-align-ld-with-business-strategy"  target="_blank"  className="btnSlide">
                                 <i className="fa-solid fa-arrow-right " style={{ color: "#EF571A" }}></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex w-100 d-flex justify-content-end mb-5 mt-4">

                                            <div className="col-4 d-flex flex-row BtnSection justify-content-evenly">
                                                {/* <a className="btns rounded-pill d-flex align-items-center justify-content-around text-decoration-none" href="#carouselExampleControls" role="button" data-bs-slide="prev">
                                                    <i className="fa-solid fa-arrow-left color"></i>
                                                </a>
                                                <a className="btns rounded-pill d-flex align-items-center justify-content-around text-decoration-none" href="#carouselExampleControls" role="button" data-bs-slide="next">
                                                    <i className="fa-solid fa-arrow-right color"></i>
                                                </a> */}
                                            </div>
                                            <div className="col-4 d-flex justify-content-end ViewallSection">
                                                <a href="https://blog.phenomecloud.com/" target="_blank" className="fs-6 text-white" style={{ textDecoration: 'none' }}>View all Insights <i className="fa-solid fa-arrow-right" style={{ color: '#EF5713' }}></i></a>
                                            </div>
                                        </div>
                    </div>
                  </div>
                </div>
                <Guides />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Agile;



