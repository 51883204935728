
import React, { useState } from "react";
import homedata from "../data/Home/agile.json";
import Marquee from "react-fast-marquee";


const TrustedOrganization = () => {

    const [isPaused, setIsPaused] = useState(false);
    return (
        <div className="container-flude TrustedBrand">
            <div className="row d-flex justify-content-around" >
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    {homedata.map((data) => {
                        const { id, title, org } = data;
                        return (
                            <div key={id}>
                                {id === 1 && (
                                    <div className="row align-items-center mt-1" key={id}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-2">
                                            <div className="container">
                                                <h6 className="text-center fw-bold">{title}</h6>
                                            </div>
                                        </div>

                                        <div className="OrgContainer">
                                            <div className="OrgImgContainer w-100 mb-2"
                                                onMouseEnter={() => setIsPaused(true)}
                                                onMouseLeave={() => setIsPaused(false)}>
                                                <Marquee speed={50} delay={0} pauseOnHover={isPaused}>
                                                    {org.map((item, index) => {
                                                        const { Img, Url } = item;
                                                        return (
                                                            <div className="image_wrapper" key={index}>
                                                                <a href={Url} target="_blank">
                                                                    <img src={require(`../image/trustedorglogos/${Img}.png`)} alt={`Logo ${item}`} />
                                                                </a>
                                                            </div>)
                                                    })}
                                                </Marquee>
                                            </div>
                                        </div>


                                    </div>
                                )}

                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TrustedOrganization;



