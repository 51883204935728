import React from 'react'
import { Banner } from '../Banner'
import { Link } from 'react-router-dom';
import FooterBrands from '../FooterBrands';
import TrustedOrganization from '../TrustedOrganization';
import { GetStarted } from '../FooterSection/GetStarted';
export const LearningCoe = ({ adata }) => {
    var Data = adata;

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Banner />
            <TrustedOrganization />
            <div className='LearningCoe'>
                {
                    Data.map((item) => {
                        const { Id, headingClassName, className, parentClassName,
                            subClassName, childClassName, HeadingImg, Heading,
                            Description, subHeading, subDescription, infoText, Data, Btn, DataToggle, Validation } = item;

                        return (
                            <div className={headingClassName} key={Id}>
                                {
                                    headingClassName !== "ProjectSection" && headingClassName !== "MoreInfoSection" && (
                                        <div className={className}>
                                            {
                                                Heading && (
                                                    <div className={parentClassName}>
                                                        <h3 className="Heading mb-3">{Heading}</h3>
                                                    </div>
                                                )
                                            }
                                            {
                                                Description && (
                                                    <div className={subClassName}>
                                                        <p className="Description mb-0">{Description}</p>
                                                    </div>
                                                )
                                            }{
                                                subDescription && (
                                                    <>{subHeading && (
                                                        <div className={parentClassName}>
                                                            <h3 className="Heading subHeading mb-3">{subHeading}</h3>
                                                        </div>
                                                    )}
                                                        <div className={subClassName}>
                                                            <p className="Description">{subDescription}</p>
                                                        </div>
                                                        {
                                                            infoText && (
                                                                <div className={subClassName}>
                                                                    <p className="Description">{infoText}</p>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                            <div className={childClassName}>
                                                {
                                                    Data.map((item) => {
                                                        const { Id, Img, className, cardName, subClassName, headingClassName, Title, Description, subDescription, Details, BtnName, BtnLink } = item;
                                                        return (
                                                            <div className={className} key={Id}>
                                                                <div className={cardName}>
                                                                    {
                                                                        Title ? (
                                                                            <>
                                                                                {Img && (
                                                                                    <img src={require(`../../image/LearningCoe/${Img}`)} alt="" />
                                                                                )}
                                                                                <div className={`card-body d-flex flex-column text-center justify-content-center ${subClassName}`}>
                                                                                    <p className={headingClassName}>{Title}</p>
                                                                                    <p className="m-0 Desc text-start">{Description}</p>
                                                                                </div>
                                                                                {
                                                                                    BtnName && (
                                                                                        <Link to={BtnLink} className='btn btn-dark mb-3 CardBtn' onClick={() => { scrollToTop(); }}>
                                                                                            {BtnName}
                                                                                            <i className="fas fa-angle-right ms-2"></i>
                                                                                        </Link>
                                                                                    )

                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <div className='row'>
                                                                                <div className="col-md-6">
                                                                                    {Img && (
                                                                                        <img src={require(`../../image/LearningCoe/${Img}`)} alt="" />
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="card-body d-flex flex-column text-center align-items-baseline justify-content-center">
                                                                                        <p className={headingClassName}>{Title}</p>
                                                                                        <p className="m-0">{Description}</p>
                                                                                        <p className='my-3'>{subDescription}</p>
                                                                                        <div className="Details">
                                                                                            <p>{Details}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                Validation === "LearningResource" && (
                                                    <FooterBrands />
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {
                                    headingClassName === "ProjectSection" && (
                                        <div className={className}>
                                            <div className={parentClassName}>
                                                <div className="col-md-8">
                                                    <h3 className="Heading mb-3 font-weight-bold">{Heading}
                                                    </h3>
                                                </div>

                                                <div className="col-md-11 mb-5">
                                                    <p className="CardDescription">{Description}</p>
                                                </div>
                                            </div>
                                            <div className={subClassName}>
                                                {Data.map((item) => {
                                                    const { Id, Img, className, Title, Description } = item;
                                                    return (
                                                        <div className={`${className} mb-3`} key={Id}>
                                                            <div>
                                                                {
                                                                    Img && (
                                                                        <img className='me-2' src={require(`../../image/LearningCoe/${Img}`)} alt="" />
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='HeadingSection'>
                                                                {
                                                                    Title && (
                                                                        <h5 className='d-block mb-0'>{Title}</h5>
                                                                    )
                                                                }
                                                                <p className="Description mb-0" dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            <div className={childClassName}>
                                                <div className="ImgSection">
                                                    {
                                                        HeadingImg && (
                                                            <img src={require(`../../image/LearningCoe/${HeadingImg}`)} alt="" />
                                                        )
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    headingClassName === "MoreInfoSection" && (
                                        <>
                                            <div className={className}>
                                                <div className={parentClassName}>
                                                    <h2 className="InfoSectionHeading mb-3 font-weight-bold">{Heading}</h2>
                                                    <p className="InfoSectionDescription">{Description}</p>
                                                    <a href="/" className='btn btn-dark HoverBtn'>
                                                        {Btn}
                                                        <i className="fas fa-angle-right ms-2"></i>
                                                    </a>

                                                </div>
                                                <div className={subClassName}>
                                                    <div className="ImgSection">
                                                        <img src={require(`../../image/LearningCoe/${HeadingImg}`)} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <FooterBrands />
                                        </>
                                    )
                                }
                                {
                                    DataToggle && (
                                        <>
                                            <div className="NavTabsSection p-5">
                                                <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                                                    {
                                                        DataToggle.map((item) => {
                                                            const { Id, ActiveName, IdName, ToggleId, ariaSelected, Heading, Img } = item;

                                                            return (
                                                                <li key={Id} className={`nav-item ${ActiveName}`} id={IdName} data-bs-toggle="pill" data-bs-target={`#${ToggleId}`} type="button" role="tab" aria-controls={ToggleId} aria-selected={ariaSelected} >
                                                                    <img src={require(`../../image/HCM Coe/${Img}`)} alt="" />
                                                                    <p className="BtnName">{Heading}</p>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div className="tab-content p-5" id="CardsContent">
                                                    <>
                                                        {
                                                            DataToggle.map((item) => {
                                                                const { Id, ToggleId, IdName, ActiveName, Data } = item;

                                                                return (
                                                                    <div key={Id} className={`tab-pane fade ${ActiveName}`} id={ToggleId} role="tabpanel" aria-labelledby={IdName}>
                                                                        {
                                                                            Data.map((item) => {
                                                                                const { Id, className, Heading, Description, subHeading, CardData } = item;
                                                                                return (
                                                                                    <div className={`${className} text-center d-flex flex-column align-items-center`} key={Id}>

                                                                                        <>
                                                                                            <h2 className='Heading'>{Heading}</h2>
                                                                                            {
                                                                                                subHeading && (
                                                                                                    <p className='subHeading'>{subHeading}</p>
                                                                                                )
                                                                                            }
                                                                                            <p className="Description">{Description}</p>
                                                                                            <div className="CardSection row justify-content-center">
                                                                                                {
                                                                                                    CardData.map((item) => {
                                                                                                        const { Id, className, subClassName, Heading, Description, subDescription, secSubDescription, btnName, Img } = item;
                                                                                                        return (
                                                                                                            <div className={`${className} pb-4`} key={Id}>
                                                                                                                <div className={`card py-2 card-radius card-box-fade-shadow ${subClassName}`}>
                                                                                                                    <img src={require(`../../image/LearningCoe/${Img}`)} alt="" />
                                                                                                                    <div className="card-body d-flex flex-column text-center justify-content-center">
                                                                                                                        <h5 className='CardHeading'>{Heading}</h5>
                                                                                                                        <p className='CardDescription mb-1'>{Description}</p>
                                                                                                                        {
                                                                                                                            subDescription && (
                                                                                                                                <div className='SubDesc'>
                                                                                                                                    <p className='CardDescription mb-1'>{subDescription}</p>
                                                                                                                                    <p className='CardDescription mb-1'>{secSubDescription}</p>
                                                                                                                                    <div className='BtnSection d-flex flex-column'>
                                                                                                                                        <a href='/' className='btn btn-dark'>{btnName}</a>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )

                                                            })
                                                        }
                                                    </>
                                                </div>
                                            </div>
                                            <FooterBrands />
                                        </>
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
            <GetStarted />
        </>
    )
}
