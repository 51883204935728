import React, { useEffect, useRef, useState } from 'react';
import bannerData from '../data/Banner.json';
import { Link, useParams } from 'react-router-dom';
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { GoArrowUpRight } from "react-icons/go";
import { SliderCarousel } from './SliderCarousel';
import { SlCalender } from "react-icons/sl";
import LazyLoad from 'react-lazyload';

export const Banner = () => {
    let { className } = useParams();
    if (className === undefined) {
        className = 'home';
    }

    // ------------------------------------
    const nextButtonRefs = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        const autoScroll = () => {
            if (!isHovered && nextButtonRefs.current) {
                nextButtonRefs.current.click(); // Simulate click on the "Next" button
            }
        };
        const intervalId = setInterval(autoScroll, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, [isHovered])

    const handleMouseEnter = () => {
        setIsHovered(true); // Set hover state to true
    };

    // Function to handle mouse leave event
    const handleMouseLeave = () => {
        setIsHovered(false); // Set hover state to false
    };

    let timeout;
    const debounce = (func, delay) => {
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), delay);
        };
    };

    window.addEventListener('scroll', debounce(() => {
        // Your scroll event code here
    }, 200));

    // Removing the touch scrolling 
    const [touchStartX, setTouchStartX] = useState(null);

    useEffect(() => {
        const carouselElement = document.getElementById('Bannercarousel');

        // Function to track touch start position
        const handleTouchStart = (e) => {
            setTouchStartX(e.touches[0].clientX);
        };

        // Function to prevent touch scrolling
        const preventTouchScroll = (e) => {
            const touchEndX = e.changedTouches[0].clientX;
            const touchDiff = touchEndX - touchStartX;

            // If touch movement is greater than a threshold, prevent default scrolling
            if (Math.abs(touchDiff) > 30) {
                e.preventDefault();
            }
        };

        if (carouselElement) {
            // Add touch event listeners
            carouselElement.addEventListener('touchstart', handleTouchStart, { passive: false });
            carouselElement.addEventListener('touchmove', preventTouchScroll, { passive: false });
        }

        return () => {
            if (carouselElement) {
                carouselElement.removeEventListener('touchstart', handleTouchStart);
                carouselElement.removeEventListener('touchmove', preventTouchScroll);
            }
        };
    }, [touchStartX]);


    return (
        <div className="container-flued">
            {
                bannerData && (
                    bannerData.map((item => {
                        const { Id, sectionClassName, BackGround, Name, Bg, Data, Img } = item
                        return (
                            className === Name && (
                                <div key={Id} className={Name}>
                                    {
                                        BackGround === 'video' ? (<video autoPlay muted loop playsInline className="background-video">
                                            <source src={require(`../image/BannerImg/${Bg}`)} type="video/mp4" />
                                        </video>) : (
                                            <LazyLoad height={200} offset={100}>
                                                <img src={require(`../image/BannerImg/${Img}`)} className='BgImg' alt="" />
                                            </LazyLoad>
                                        )
                                    }
                                    {
                                        Name !== "home" && (
                                            <div className="area">
                                                <ul className="circles">
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {
                                        <div id="Bannercarousel" className="carousel slide" key={Id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <div className={`${sectionClassName} carousel-inner`}>
                                                {
                                                    BackGround === 'video' && (
                                                        <div className="fixed-carousel">
                                                            <SliderCarousel />
                                                        </div>
                                                    )
                                                }
                                                {Data.map((item) => {
                                                    const { Id, Name, Description, SubDescription, Button, Img, Start, className, subClass, Url, downContent, HeadingClass } = item;
                                                    return (
                                                        <div className={`carousel-item ${Start}`} key={Id}>
                                                            <div className={`${className} row`}>
                                                                <div className={`BannerHeading ${subClass}`}>
                                                                    <div className="MainSection">
                                                                        <h3 className={`Heading w-100 ${HeadingClass ? HeadingClass : ''}`} title={Name}>{Name}</h3>
                                                                        {Description && (
                                                                            <p className="Description w-100" title={Description}>{Description}</p>
                                                                        )}
                                                                        {SubDescription && (
                                                                            <p className="Description" title={SubDescription}>{SubDescription}</p>
                                                                        )}
                                                                    </div>
                                                                    {Url && (
                                                                        <div className="BtnSections">
                                                                              {Name === "Let's talk. We'd love to hear from you first." ? (
                                                                                <Link to={Url}>
                                                                                     <button className="btn HoverBtn btn-dark d-flex text-align-left align-items-center ms-5"> <SlCalender /> <span style={{paddingLeft:'10px'}}>{Button}</span>
                                                                                       
                                                                                  </button>
                                                                                </Link>  ) : (
                                                                               <Link to={Url}>
                                                                               <button className="btn HoverBtn btn-dark d-flex text-align-left align-items-center ms-5">{Button}
                                                                                   <GoArrowUpRight />
                                                                               </button>
                                                                           </Link>
                                                                          )}
                                                                                                                                                       
                                                                          
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                {Img && (
                                                                    <div className="ImgBox col">
                                                                        <LazyLoad offset={100}>
                                                                            <img className="BannerImg" src={require(`../image/BannerImg/${Img}`)} alt="" loading="lazy" />
                                                                        </LazyLoad>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {
                                                                downContent && (
                                                                    <div className="downContent position-absolute bottom-0 text-white w-100">
                                                                        <div className="">
                                                                            <div className="row justify-content-center">
                                                                                {
                                                                                    downContent.map((item, index) => {
                                                                                        const { Name } = item;
                                                                                        return (
                                                                                            <h6 key={index} className='col-md-3 d-flex justify-content-center'>{Name}</h6>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {BackGround === 'video' ? (
                                                <div id="nav">
                                                    <button id="next" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={nextButtonRefs} type="button" data-bs-target="#Bannercarousel" data-bs-slide="next">
                                                        <SlArrowRight />
                                                    </button>
                                                    <button id="prev" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} type="button" data-bs-target="#Bannercarousel" data-bs-slide="prev">
                                                        <SlArrowLeft />
                                                    </button>
                                                </div>
                                            ) : (<p></p>)}
                                        </div>
                                    }
                                </div>
                            )
                        )
                    }))
                )
            }
        </div>
    )
}
