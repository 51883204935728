import React, { useState } from 'react'
import { Banner } from './Banner'
import TrustedOrganization from './TrustedOrganization'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import AcceleratorData from '../data/Accelerator.json'
import '../style/EDT.css'
import { GoHorizontalRule, GoPlus } from 'react-icons/go';
import { AccordionButton } from 'react-bootstrap';
import { GetStarted } from './FooterSection/GetStarted';
import { Helmet } from 'react-helmet';

const Accelerator = () => {
    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };


    return (
        <>
        <Helmet>
                <title>Accelerate Your Business Processes with Our Tailored Solutions</title>
                <meta name="description" content="See how Phenom eCloud’s pre-built and custom process accelerators can boost your competitive edge by optimizing and speeding up your processes to make you more efficient and effective so you can deliver more value." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {AcceleratorData.map((item, index) => {
                const { id, title, desc, section, bannerImage, accordians, infotext, grid, sub_title, card } = item;
                return (
                    <div className="" key={id}>

                        {
                            id == 1 && (
                                <div className="row d-flex justify-content-center align-items-center py-4 ">
                                    <div className="col-md-10">
                                        <p className="text-center fw-bold line-_height mb-4">{desc}</p>
                                        <h4 className="  text-center fw-bold mb-4">{title}</h4>
                                        <div className=" row d-flex justify-content-center What_Are_Accelerator">
                                            <div className="col-md-4 col-sm-12 col-12">
                                                <div>
                                                    <img src={require(`../image/Accelerator/${bannerImage}.png`)} className="img-fluid " />
                                                </div>
                                            </div>
                                            <div className="col-md-7 col-sm-12 col-12">
                                                {
                                                    infotext.map((data, index) => (
                                                        <div className="head_on_desc_body mb-3">
                                                            {data.defination && <p className="fw-bold mb-4 line-_height"  >{data.defination}</p>}
                                                            {data.header && <h4 style={{ fontWeight: 'bold', lineHeight: '20px' }}>{data.header}</h4>}
                                                            <div className="col-lg-9 col-md-9 col-sm-12 col-12 d-flex flex-column  " style={{ paddingTop: '16px' }}>

                                                                <Accordion className='d-flex flex-column EcCoe'>
                                                                    {data.accordians.map((item, index) => {
                                                                        const { header, desc } = item;
                                                                        return (
                                                                            <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                                <AccordionButton className='Dropdown-Bg-Color'
                                                                                    onClick={() => handleToggle(index.toString())}
                                                                                    aria-expanded={expandedItem === index.toString()}
                                                                                >
                                                                                    <div className="d-flex justify-content-between align-items-center w-100">
                                                                                        <span className='fw-bolder sm-font-size'>{header}</span>
                                                                                        {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                                    </div>
                                                                                </AccordionButton>

                                                                                <Accordion.Body key={index} className='py-1'>
                                                                                    <p className='text-start'>{desc}</p>
                                                                                </Accordion.Body>

                                                                            </Accordion.Item>
                                                                        );
                                                                    })}
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            id == 2 && (
                                <div className="row d-flex justify-content-center align-items-center allcoe_service_card_bg">
                                    {
                                        section.map((item1, index1) => {
                                            return (<>
                                                {item1.title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{item1.title}</h3>}
                                                <div className="title_desc  d-flex flex-column align-items-center">
                                                    {
                                                        item1.desc.map((value, items) => (
                                                            value && <p key={items} className="text-center service_desc" style={{ width: '50%', fontSize: '14px' }}>{value.text}</p>
                                                        ))
                                                    }
                                                </div>




                                                <div className={`row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3 ${item1.title.replace(/\s+/g, '_')}_card`}>
                                                    {
                                                        item1.card.map((data, index) => {
                                                            return <div className="col-md-3 col-dm-12 m-3 our_service_card card d-flex flex-column accelerator_card_with_arrow card-bg-color  p-3">

                                                                <div className='d-flex justify-content-between align-items-center'>

                                                                    <p className="fw-bold">{data.card_name}</p>
                                                                    <img src={require(`../image/Accelerator/${data.icon}`)} alt="" srcset="" className='icon-box' />
                                                                </div>
                                                                <div className="card-body px-1 d-flex flex-column">

                                                                    {
                                                                        index1 == 0 ? (<>
                                                                            {
                                                                                data.card_desc.map((data1, index2) => (
                                                                                    <div className='d-flex col-md-11 '><span style={{ marginRight: '3%' }}><FontAwesomeIcon icon={faArrowRight} style={{ color: "#5CB6F2" }} /></span> <p>{data1.text}</p></div>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        ) :
                                                                            (<p className="our_service_card_desc">{data.card_desc}</p>)
                                                                    }
                                                                </div>
                                                                {
                                                                    data.card_url && (
                                                                        <div className="  col-md-12 d-flex justify-content-end align-items-center">
                                                                            <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                                <p >Discover your possibilities</p></Link>
                                                                            <Link to={data.card_url}>
                                                                                <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>



                                                        })
                                                    }


                                                </div>
                                            </>)




                                        })
                                    }


                                </div>


                            )
                        }


                    </div>
                );
            })}
            <GetStarted />
        </>
    )
}

export default Accelerator