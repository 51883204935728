
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import './App.css';
import './style/NavBar.css';
import './style/Banner.css';
import './style/About.css';
import './style/OurPartners.css';
import './style/Hcm_Services.css';
import './style/LearningCOE.css';
import './style/OurPartnersBarnds.css';
import './style/agile.css';
import './style/Home.css';
import './style/hcmcoe.css';
import './style/Footer.css';
import './style/MobileApplication.css';
import './style/AdvanceAnalytic.css';
import './style/ERP.css';
import './style/EC.css';
import Header from './components/Header';
import { About } from './components/About';
import { OurPartners } from './components/OurPartners';
import Subrout from './components/SubRouting/Subrout';
import Hcm from './components/SubRouting/Hcm';
import Learning from './components/SubRouting/Learning';
import { LearningCoe } from './components/LearningCOE/LearningCoe';
import CloudTransformation from './components/SubRouting/CloudTransformation';
import { OurPartnersBrands } from './components/OurPartners/OurPartnersBrands';
// JsonData
import AboutUs from './data/About.json'
import LearningCOE from './data/LearningCoe/LearningCOE.json';
import ManagementSystems from './data/LearningCoe/ManagementSystems.json';
import CustomLearning from './data/LearningCoe/CustomLearning.json';
import MobileLearning from './data/LearningCoe/MobileLearning.json';
import Gamifications from './data/LearningCoe/LearningStylesAndGamification.json';
import ResourceCenter from './data/LearningCoe/LearningResourceCenter.json';
import LearningPortals from './data/LearningCoe/LearningPortals.json';
import Workday from './data/OurPartnears/Workday.json'
import SalesForce from './data/OurPartnears/SalesForce.json'
import Sumtotal from './data/OurPartnears/Sumtotal.json'
import Cornerstone from './data/OurPartnears/Cornerstone.json'
import Ceridian from './data/OurPartnears/Ceridian.json'
import Servicenow from './data/OurPartnears/Servicenow.json'
import ChangeManagementData from './data/HCMLearning/ChangeManagement.json'
import BusinessWorkShop from './data/HCMLearning/BusinessWorkshop.json'
import ConfigurationWorkShop from './data/HCMLearning/ConfigurationWorkshop.json'
import IntegrationData from './data/HCMLearning/Integration.json'
import AnalyticsDeploymentData from './data/HCMLearning/AnalyticsDeployment.json'
import TestingData from './data/HCMLearning/Testing.json'
import GamificationData from './data/HCMLearning/Gamification.json'
import VirtualRealityData from './data/HCMLearning/VirtualReality.json'
import AnalyticsResourceData from './data/HCMLearning/AnalyticsResource.json'
import TechnologyResourceData from './data/HCMLearning/TechnologyResource .json'
import serviceData from './data/HCMLearning/ManagedServices.json'
import ContentResourceData from './data/HCMLearning/ContentResourceCenter.json'
import AugmenetedData from './data/HCMLearning/AugmentedReality.json'
import UiDesignData from './data/HCMLearning/UIDesign.json'
import DevelopmentData from './data/HCMLearning/LearningDevelopment.json'
import AdminResourceData from './data/HCMLearning/AdminResource.json'
import COEAnalyticsdeployment from './data/HCM-COE/COE-Analytics-deployment.json'
import COEAdminResource from './data/HCM-COE/COE-Admin-Resource.json'
import COEintegration from './data/HCM-COE/COE-Integration.json'
import COETechnologyResource from './data/HCM-COE/COE-TechnologyResource.json'
import COEContentResourceCenter from './data/HCM-COE/COE-Content-Resource.json'
import COEManagedServices from './data/HCM-COE/COE-managed-services.json'
import COEAnalyticsResourceData from './data/HCM-COE/COE-Analytics.json'
import COEChangeManagementData from './data/HCM-COE/COE-ChangeManagement.json'
import Erp_Coe from './data/ERP/Erp_Coe.json'
import Ec_Coe from './data/EC/EC_Coe.json'
import Eb_Coe from './data/EB/EB_Coe.json'
import talent_aquasition from './data/HCM/talent_aquasition.json'
import cookiePolicy from './data/CookiePolicy.json'
import SecurityPolicy from './data/SecurityPolicy.json'



import { Home } from './components/Home/Home';
import HCMCOE from './components/HCM/HCM_COE';
import { Footer } from './components/FooterSection/Footer';
import { PrivacyPolicy } from './components/FooterSection/PrivacyPolicy';
import TalentManagement from './components/HCM/Talent_Management';
import Compensation from './components/HCM/Compensation';
import EnterpriseIntegrations from './components/HCM/EnterpriseIntegration';
import HCMLearning from './components/HCM/HCMLearning';
import Payroll from './components/HCM/Payroll';
import PeopleAnalytics from './components/HCM/PeopleAnalytics';
import EmployeePortals from './components/HCM/employee_portals';
import HCMResourceCenter from './components/HCM/HCM-Resource-Center';
import { MobileApplication } from './components/MobileApplication';
import AdvanceAnalytic from './components/AdvanceAnalytic';
import DigitalTransformation from './components/DigitalTransformation';
import { ChangeManagement } from './components/HCM/Learning/ChangeManagement';
import { BusinessWorkshop } from './components/HCM/Learning/BusinessWorkshop';
import PrivacyPolicyLayout from './components/PrivacyPolicyLayout';
import Disclaimer from './components/Disclaimer';
import CookiePolicy from './components/CookiePolicy';
import SiteMap from './components/SiteMap';
import ManagedServices from './components/HCM/Learning/ManagedServices';
import ContentResourceCenter from './components/HCM/Learning/ContentResourceCenter';
import AugmentedReality from './components/HCM/Learning/AugmentedReality';
import UIDesign from './components/HCM/Learning/UIDesign';
import LearningDevelopment from './components/HCM/Learning/LearningDevelopment';
import AdminResource from './components/HCM/Learning/AdminResource';
import { ConfigurationWorkshop } from './components/HCM/Learning/ConfigurationWorkshop';
import { Integration } from './components/HCM/Learning/Integration';
import { AnalyticsDeployment } from './components/HCM/Learning/AnalyticsDeployment';
import { Testing } from './components/HCM/Learning/Testing';
import { Gamification } from './components/HCM/Learning/Gamification';
import { VirtualReality } from './components/HCM/Learning/VirtualReality';
import { AnalyticsResource } from './components/HCM/Learning/AnalyticsResource';
import { TechnologyResource } from './components/HCM/Learning/TechnologyResource ';
import StaffingAugmentation from './components/HCM/HCM-COE/StaffingAugmentation';
import HcmDeploymentWorkshops from './components/HCM/HCM-COE/HcmDeploymentWorkshops';
import Coe from './components/SubRouting/Coe';
import Simulation from './components/HCM/Learning/Simulation';
import Testingdata from './components/HCM/HCM-COE/Testing';
import Contact from './components/Contact';
import HCM_AMS from './components/HCM/HCM_AMS';
import { ERP_COE } from './components/ERP/ERP_COE';
import PageNotFound from './components/PageNotFound';
import AdvisoryService from './components/AdvisoryService';
import EDT_COE from './components/EDT/EDT_COE';
import Accelerator from './components/Accelerator';
import { EC_COE } from './components/EC/EC_COE';
import { EB_COE } from './components/EB/EB_COE';
import { Talent_Aquasition } from './components/HCM/Talent_Aquasition';
import { TermsandConditions } from './components/TermsandConditions';
import { PageNotFoundError } from './components/PageNotFoundError';


function App() {

  return (
    <div className="App">
      <div className='Home_container'>
        <Router>
          <Header />
          <Routes >
            <Route path="/" element={<Home />} />

            {/* <Route path="/services" element={<Subrout />} > */}
            <Route path="hcm" element={<Hcm />} >
              <Route path="coe" element={<Coe />} >
                <Route path=":className" element={<DynamicComponent />} />
              </Route>

              <Route path="learning" element={<Learning />} >
                <Route path=":className" element={<DynamicComponent />} />
              </Route>
              <Route path=":className" element={<DynamicComponent />} />
            </Route>


            <Route path="enterprise-resource-planning" element={<Hcm />} >
              <Route path=":className" element={<DynamicComponent />} />
            </Route>

            <Route path="enterprise-digital-transformation" element={<Hcm />} >
              <Route path=":className" element={<DynamicComponent />} />
            </Route>

            <Route path="enterprise-business" element={<Hcm />} >
              <Route path=":className" element={<DynamicComponent />} />
            </Route>

            <Route path="enterprise-cloud" element={<Hcm />} >
              <Route path=":className" element={<DynamicComponent />} />
            </Route>

            <Route path="learning" element={<Learning />} >
              <Route path=":className" element={<DynamicComponent />} />
            </Route>

            <Route path="tech-alliance" element={<CloudTransformation />} >
              <Route path=":className" element={<DynamicComponent />} />
            </Route>

            <Route path=":className" element={<DynamicComponent />} />

            <Route path="/:className" element={<DynamicComponent />} />

            {/* Catch-all 404 route should be the last */}
            {/* <Route path="*" element={<PageNotFound />} /> */}
            <Route path="*" element={<PageNotFoundError />} />
          </Routes>
          <Footer />
          <PrivacyPolicy />
        </Router>
      </div>


    </div>
  );
}



const validRoutes = [
  'hcm-coe',
  'talentmanagement',
  'compensation',
  'talent-aquasition',
  'enterprise-integrations',
  'hcm-learning',
  'payroll',
  'people-analytics',
  'core-hcm',
  'hcm-resource-center',
  'hcm-ams',
  'staffing-and-augmentation-services',
  'Testing',
  'Analytics-deployment',
  'Resource-Technology',
  'Resource',
  'Integration',
  'Content-resource-center',
  'Managed-services',
  'Analytics',
  'analytics',
  'management',
  'change-management',
  'business-workshop',
  'configuration-workshop',
  'integration',
  'analytics-deployment',
  'testing',
  'gamification',
  'virtual-reality',
  'technology',
  'managed-services',
  'content-resource-center',
  'augmented-reality',
  'user-experience-and-design',
  'development',
  'admin-resource',
  'simulation',
  'Hcm-Deployment-Workshops',
  'erp-coe-offers-to-you',
  'edt-coe-offers-to-you',
  'ec-coe-offers-to-you',
  'eb-coe-offers-to-you',
  'learning-coe',
  'learningmanagementsystems',
  'learning-portals',
  'customlearning',
  'mobilelearning',
  'learningstylesandgamification',
  'learning-resource-center',
  'employee-portals',
  'workday',
  'salesforce',
  'sumtotal',
  'cornerstone',
  'ceridian',
  'servicenow',
  'mobile',
  'Advance',
  'Digital',
  'aboutUs',
  'ourPartners',
  'PrivacyPolicy',
  'disclaimer',
  'cookiePolicy',
  'securitypolicy',
  'site-map',
  'accelerator',
  'advisory-services',
  'terms&conditions',
  'contact'
];

// --------------------------Page-Tile-Map------------------
const pageTitleMap = {
  'hcm-coe': 'Phenom-E-Cloud Human Capital Management Center of Excellence',
  'erp-coe-offers-to-you': 'Phenom-E-Cloud Enterprise Resource Planning Service & Support',
  'eb-coe-offers-to-you': 'Phenom-E-Cloud Enterprise Business Center of Excellence',
  'ec-coe-offers-to-you': 'Phenom-E-Cloud Unlock the Power and Flexibility of the Cloud',
  'edt-coe-offers-to-you': 'Phenom-E-Cloud Realize Your Business Potential with Digital Transformation Services',
  'talentmanagement': 'Phenom-E-Cloud Talent Management for HCM',
  'compensation': 'Phenom-E-Cloud Compensation Implementation, Optimization, and Support',
  'talent-aquasition': 'Phenom-E-Cloud Talent Acquisition for HCM',
  'enterprise-integrations': 'Phenom-E-Cloud Enterprise Application Integration Services',
  'hcm-learning': 'Phenom-E-Cloud HCM Learning',
  'payroll': 'Phenom-E-Cloud Payroll Services, Implementation, Optimization, and Support',
  'people-analytics': 'Phenom-E-Cloud People Analytics',
  'core-hcm': 'Phenom-E-Cloud Core HCM Designed for Your Business',
  'hcm-resource-center': 'Phenom-E-Cloud HCM Resource Center (AMS)',
  'employee-portals': 'Phenom-E-Cloud ',
  'aboutUs': 'Phenom-E-Cloud About Us',
  'management': 'Phenom-E-Cloud Change Management for HCM',
  'ourPartners': 'Phenom-E-Cloud Our Partners',
  'contact': 'Phenom-E-Cloud Contact Us',
  'accelerator': 'Phenom-E-Cloud Accelerate Your Business with Specialized Solutions',
  'advisory-services': 'Phenom-E-Cloud Advisory Services for Human Resources',
  'workday': 'Phenom-E-Cloud Transformation Services for Workday',
  'sumtotal': 'Phenom-E-Cloud LMS Dashboards for SumTotal',
  'cornerstone': 'Phenom-E-Cloud Optimization Services for Cornerstone',
  'ceridian': 'Phenom-E-Cloud Optimization Services for Ceridian',
  'salesforce': 'Phenom-E-Cloud Managed Services for salesforce',
  'servicenow': 'Phenom-E-Cloud Managed Services for servicenow',
  'Digital': 'Your Path to Digital HR Transformation',
  'mobile': 'Phenom-E-Cloud Mobile App Developement and Support',
  'Advance': 'Phenom-E-Cloud Predictive Business Intelligence',
  'learning-coe': 'Phenom-E-Cloud eLearning Solutions',
  'learningmanagementsystems': 'Phenom-E-Cloud eLearning Management',
  'learning-portals': 'Learning Portal Design and Support',
  'customlearning': 'Phenom-E-Cloud Custom eLearning Service and Support',
  'mobilelearning': 'Phenom-E-Cloud Mobile Learning Service and Support',
  'learningstylesandgamification': 'Phenom-E-Cloud Learning Strategies',
  'learning-resource-center': 'Phenom-E-Cloud Learning Resource Center',
  'Hcm-Deployment-Workshops': 'Phenom-E-Cloud HCM Deployment Workshops',
  'Testing': 'Phenom-E-Cloud HCM Technology Testing Service',
  'Analytics-deployment': 'Phenom-E-Cloud Analytics Deployment and Support',
  'Integration': 'Integration Services for HCM ',
  'Resource': 'Admin Resource Center For HCM',
  'Analytics': 'Phenom-E-Cloud People Analytics Service and Support',
  'Resource-Technology': 'Phenom-E-Cloud HR Technology Resource Center',
  'Content-resource-center': 'Phenom-E-Cloud Custom Learning Content',
  'Managed-services': 'Phenom-E-Cloud Managed Services Center',
  'staffing-and-augmentation-services': 'Staffing and Augmentation Services',
  'change-management': 'Change Management | Sumtotal Technology Phenom-E-Cloud',
  'business-workshop': 'Phenom-E-Cloud Business Workshop For LMS Deployement',
  'configuration-workshop': 'Phenom-E-Cloud LMS Configuration Service',
  'integration': 'Phenom-E-Cloud Learning Technology Integration',
  'testing': 'Phenom-E-Cloud Testing Service',
  'analytics-deployment': 'Phenom-E-Cloud LMS Analytics Deployment',
  'gamification': 'Gamification for Learning Design',
  'augmented-reality': 'Phenom-E-Cloud Augmeneted Reality Learning Design',
  'virtual-reality': 'Phenom-E-Cloud Virtual Reality Learning Design ',
  'simulation': 'Phenom-E-Cloud eLearning Stimulation Service',
  'user-experience-and-design': 'Phenom-E-Cloud eLearning Experience Design',
  'development': 'Phenom-E-Cloud eLearning Developement',
  'admin-resource': 'Phenom-E-Cloud LMS Administration Resource Center',
  'analytics': 'Phenom-E-Cloud Learning Analytics Center',
  'technology': 'Phenom-E-Cloud Learning Technology Resource Center',
  'content-resource-center': 'Phenom-E-Cloud Learning Content Resource Center',
  'managed-services': 'Phenom-E-Cloud Managed Learning Service'

};




const DynamicComponent = () => {
  const { className } = useParams();

  useEffect(() => {

    const defaultTitle = 'Phenom-E-Cloud';
    const newTitle = pageTitleMap[className] || defaultTitle;  // Set title to default if className is not in pageTitleMap
    document.title = newTitle;

    window.scrollTo(0, 0);
  }, [className]);

  // Check if className is valid
  if (!validRoutes.includes(className)) {
    // return <PageNotFound />;
    return <PageNotFoundError />;
  }

  // Route to components based on className
  switch (className) {
    case 'hcm-coe':
      return <HCMCOE />;
    case 'talentmanagement':
      return <TalentManagement />;
    case 'compensation':
      return <Compensation />;
    case 'talent-aquasition':
      return <Talent_Aquasition adata={talent_aquasition} />;
    case 'enterprise-integrations':
      return <EnterpriseIntegrations />;
    case 'hcm-learning':
      return <HCMLearning />;
    case 'payroll':
      return <Payroll />;
    case 'people-analytics':
      return <PeopleAnalytics />;
    case 'core-hcm':
      return <EmployeePortals />;
    case 'hcm-resource-center':
      return <HCMResourceCenter />;
    case 'hcm-ams':
      return <HCM_AMS />;
    case 'staffing-and-augmentation-services':
      return <StaffingAugmentation />;
    case 'Testing':
      return <Testingdata />;
    case 'Analytics-deployment':
      return <AnalyticsDeployment adata={COEAnalyticsdeployment} />;
    case 'Resource-Technology':
      return <TechnologyResource adata={COETechnologyResource} />;
    case 'Resource':
      return <AdminResource adata={COEAdminResource} />;
    case 'Integration':
      return <Integration adata={COEintegration} />;
    case 'Content-resource-center':
      return <ContentResourceCenter adata={COEContentResourceCenter} />;
    case 'Managed-services':
      return <ManagedServices adata={COEManagedServices} />;
    case 'Analytics':
      return <AnalyticsResource adata={COEAnalyticsResourceData} />;
    case 'analytics':
      return <AnalyticsResource adata={AnalyticsResourceData} />;
    case 'management':
      return <ChangeManagement adata={COEChangeManagementData} />;
    case 'change-management':
      return <ChangeManagement adata={ChangeManagementData} />;
    case 'business-workshop':
      return <BusinessWorkshop adata={BusinessWorkShop} />;
    case 'configuration-workshop':
      return <ConfigurationWorkshop adata={ConfigurationWorkShop} />;
    case 'integration':
      return <Integration adata={IntegrationData} />;
    case 'analytics-deployment':
      return <AnalyticsDeployment adata={AnalyticsDeploymentData} />;
    case 'testing':
      return <Testing adata={TestingData} />;
    case 'gamification':
      return <Gamification adata={GamificationData} />;
    case 'virtual-reality':
      return <VirtualReality adata={VirtualRealityData} />;
    case 'technology':
      return <TechnologyResource adata={TechnologyResourceData} />;
    case 'managed-services':
      return <ManagedServices adata={serviceData} />;
    case 'content-resource-center':
      return <ContentResourceCenter adata={ContentResourceData} />;
    case 'augmented-reality':
      return <AugmentedReality adata={AugmenetedData} />;
    case 'user-experience-and-design':
      return <UIDesign adata={UiDesignData} />;
    case 'development':
      return <LearningDevelopment adata={DevelopmentData} />;
    case 'admin-resource':
      return <AdminResource adata={AdminResourceData} />;
    case 'simulation':
      return <Simulation />;
    case 'Hcm-Deployment-Workshops':
      return <HcmDeploymentWorkshops adata={HcmDeploymentWorkshops} />;
    case 'erp-coe-offers-to-you':
      return <ERP_COE adata={Erp_Coe} />;
    case 'edt-coe-offers-to-you':
      return <EDT_COE />;
    case 'ec-coe-offers-to-you':
      return <EC_COE adata={Ec_Coe} />;
    case 'eb-coe-offers-to-you':
      return <EB_COE adata={Eb_Coe} />;
    case 'learning-coe':
      return <LearningCoe adata={LearningCOE} />;
    case 'learningmanagementsystems':
      return <LearningCoe adata={ManagementSystems} />;
    case 'learning-portals':
      return <LearningCoe adata={LearningPortals} />;
    case 'customlearning':
      return <LearningCoe adata={CustomLearning} />;
    case 'mobilelearning':
      return <LearningCoe adata={MobileLearning} />;
    case 'learningstylesandgamification':
      return <LearningCoe adata={Gamifications} />;
    case 'learning-resource-center':
      return <LearningCoe adata={ResourceCenter} />;
    case 'workday':
      return <OurPartnersBrands adata={Workday} />;
    case 'salesforce':
      return <OurPartnersBrands adata={SalesForce} />;
    case 'sumtotal':
      return <OurPartnersBrands adata={Sumtotal} />;
    case 'cornerstone':
      return <OurPartnersBrands adata={Cornerstone} />;
    case 'ceridian':
      return <OurPartnersBrands adata={Ceridian} />;
    case 'servicenow':
      return <OurPartnersBrands adata={Servicenow} />;
    case 'mobile':
      return <MobileApplication />;
    case 'Advance':
      return <AdvanceAnalytic />;
    case 'Digital':
      return <DigitalTransformation />;
    case 'aboutUs':
      return <About adata={AboutUs} />;
    case 'ourPartners':
      return <OurPartners />;
    case 'PrivacyPolicy':
      return <PrivacyPolicyLayout />;
    case 'disclaimer':
      return <Disclaimer />;
    case 'cookiePolicy':
      return <CookiePolicy adata={cookiePolicy} />;
    case 'securitypolicy':
      return <CookiePolicy adata={SecurityPolicy} />;
    case 'site-map':
      return <SiteMap />;
    case 'accelerator':
      return <Accelerator />;
    case 'advisory-services':
      return <AdvisoryService />;
    case 'terms&conditions':
      return <TermsandConditions />;
    case 'contact':
      return <Contact />;
    // default:
    //   return <PageNotFound />;
    default:
      return <PageNotFoundError />;
  }
};



export default App;
