import React from 'react'
import Plogo from '../../image/navLogo.png'
import '../../style/Footer.css'
import { Link } from 'react-router-dom'

export const PrivacyPolicy = () => {
    const scrollTp = () => {
        window.scrollTo(0, 0);
    }
    return (
        <div className="container-fluid Privacy_policy_body" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
            <div className="Privacy_policy_body_container d-flex w-100 justify-content-center">
                <div className=" row d-flex justify-content-between align-items-center py-2  text-center small w-100">
                    <div className="col-lg-2 col-md-2 col-sm-12 text-start p-0   privacy_logo width_adjust">
                        <Link to={'/'} onClick={scrollTp}><img src={Plogo} height={40}></img></Link>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12   d-flex  flex-column small  font-weight-100 width_adjust privacy_cookies_content">
                        <Link to={'/PrivacyPolicy'}> <span className=" px-2" title='Privacy Policy'>Privacy Policy</span></Link>
                        {/* <Link to={'/site-map'}><span className=" px-2" title='Site Map'>Site Map</span></Link> */}
                        <Link to={'/securitypolicy'}>  <span className=" px-2" title='Security policy'>Security Policy</span></Link>
                    </div>
                    <div className=" col-lg-1 col-md-1 col-sm-12   d-flex  flex-column small font-weight-100 width_adjust disclaimer_content">
                        <Link to={'/cookiePolicy'} > <span className=" px-2" title='Cookie Policy'>Cookie Policy</span></Link>
                        <Link to={'/disclaimer'} > <span className=" px-2" title='Disclaimer'>Disclaimer</span></Link>
                    </div>
                    <div className=" col-lg-2 col-md-2 col-sm-12  d-flex flex-column small font-weight-100 width_adjust">
                        <Link to={'/terms&conditions'}>  <span className=" px-2" title='Term & Condition'>Term & Condition</span></Link>
                        {/* <a href="">  <span className=" px-2" title='Security policy'>Security policy</span></a>  */}
                    </div>
                    <div className=" col-lg-3 col-md-3 col-sm-12   width_adjust">
                        <span className=" col-lg-3 col-md-3 col-sm-12 col-6  small font-weight-100">
                            <span title='@ 2024 Phenom Enterprise Cloud. All Rights Reserved.'>@ 2024 Phenom Enterprise Cloud. All Rights Reserved. </span>
                        </span>
                    </div>
                    <div className=" col-lg-2 col-md-2 col-sm-12   d-flex  width_adjust  logo_section">

                        <a href="https://www.facebook.com/phenomecloud/" className="nav-link px-1">
                            <i className="fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="https://twitter.com/phenomecloud" className="nav-link px-1">
                            <i className="fa-brands fa-x-twitter"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/phenomecloud" className="nav-link px-1">
                            <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                        <a href="https://www.youtube.com/c/Phenomeloud" className="nav-link px-1">
                            <i className="fab fa-youtube"></i>
                        </a>
                        <a href="https://www.instagram.com/phenomecloud/" className="nav-link px-1">
                            <i className="fa-brands fa-instagram"></i>
                        </a>


                    </div>
                </div>
            </div>
        </div>
    )
}
