import React from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Banner } from './Banner';
import Sliders from './Sliders';
import { GetStarted } from './FooterSection/GetStarted';
import TrustedOrganization from './TrustedOrganization';



export const About = ({ adata }) => {
    var aboutUs = adata
    return (
        <>
            <Banner />
            <TrustedOrganization />
            <div className="container-fluid p-0 overflow-hidden">
                {
                    aboutUs.map((item) => {
                        const { Id, Heading, Img, Data } = item;
                        return (
                            <div key={Id}>
                                {
                                    Id === 1 && (
                                        <div className="row p-5 card_section_bg abtUs_body justify-content-center">
                                            <div className="col-md-11 d-flex WeAre card_body_section_bg align-items-center">
                                                <div className="col-md-4 ps-5">
                                                    <div className="WeAreImg">
                                                        <img src={require(`../image/About/${Img}`)} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="Content px-5 ">
                                                        <div className="Heading text-start mb-2">
                                                            <h3 className='fw-bold'>{Heading}</h3>
                                                        </div>
                                                        <div className="Description text-start">
                                                            {
                                                                Data.map((item) => {
                                                                    const { Id, Name } = item;
                                                                    return (
                                                                        <div key={Id}>
                                                                            <p className='mb-3'>{Name}</p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    Id === 2 && (
                                        <div className="CardCarousel" style={{ backgroundColor: '#d3e6ee' }}>
                                            <div className="CarouselContainer p-4">
                                                <h4 className='text-dark fw-bold pb-5'>{Heading}</h4>
                                                <Sliders activeSlide={2} />
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    Id === 3 && (
                                        <>
                                            <div className="row justify-content-center card_section_bg">
                                                <div className='col-md-11'>
                                                    <div className="PromiseHeading col pt-4 d-flex justify-content-center">
                                                        <h1>{Heading}</h1>
                                                    </div>
                                                    <div className="cardContainer px-5">
                                                        <div className="row ">

                                                            {
                                                                Data.map((item) => {
                                                                    const { Id, Name, Img } = item;
                                                                    return (
                                                                        <div className="col-md-4" key={Id}>
                                                                            <div className="card">
                                                                                <div className="card-body">
                                                                                    <img src={require(`../image/About/${Img}`)} alt="" />
                                                                                    <p className='Description'>{Name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </>

                                    )
                                }
                            </div>
                        )
                    })
                }

            </div>
            <GetStarted />
        </>
    )
}
