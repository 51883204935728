import React from 'react'
import { Banner } from '../Banner'
import FooterBrands from '../FooterBrands';
import TrustedOrganization from '../TrustedOrganization';
import { Link } from 'react-router-dom';
import { GoChevronRight } from 'react-icons/go';
import { GetStarted } from '../FooterSection/GetStarted';

export const OurPartnersBrands = ({ adata }) => {
    var Data = adata;
    return (
        <>
            <Banner />
            <TrustedOrganization />
            <div className="OurPartners">
                {
                    Data.map((item) => {
                        const { Id, bottomText, headingClassName, className, parentClassName, subClassName, childClassName, Heading, Description, HeadingImg, Data, DataToggle, Btn, TabData, HeadingContent, Url } = item;
                        return (
                            <div className={headingClassName} key={Id}>
                                {
                                    headingClassName !== "ProjectServiceSection" && headingClassName !== "MoreInfoSection" && (
                                        <>
                                            {className && (
                                                <div className={className}>
                                                    <div className={parentClassName}>
                                                        <h3 className='mb-3'>{Heading}</h3>
                                                    </div>
                                                    {
                                                        Description && (
                                                            <div className={subClassName}>
                                                                <p className="Description line-_height" dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        TabData && (
                                                            <div className='row justify-content-center mt-3'>
                                                                <ul className="list-group col-md-4 text-start mb-4">
                                                                    {
                                                                        TabData.map((item) => {
                                                                            const { Id, ActiveName, IdName, ToggleId, ariaSelected, Heading, Img } = item;
                                                                            return (
                                                                                <li className={`list-group-item ${ActiveName}`} id={IdName} data-bs-toggle="pill" data-bs-target={`#${ToggleId}`} type="button" role="tab" aria-controls={ToggleId} aria-selected={ariaSelected} key={Id}>
                                                                                    <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${Img}`)} alt="" />
                                                                                    {Heading}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                <div className="tab-content text-start col-md-5 justify-content-center align-items-center row" id="NavTabSectionContent">
                                                                    {
                                                                        TabData.map((item, index) => {
                                                                            const { Id, ActiveName, IdName, ToggleId, NavTabToggle } = item;
                                                                            return (
                                                                                <div className={`tab-pane fade ${ActiveName} w-100`} id={ToggleId} role="tabpanel" aria-labelledby={IdName} key={Id}>
                                                                                    {
                                                                                        NavTabToggle.map((item) => {
                                                                                            const { Id, Description, subDescription, childDescription, subChildDescription, Img, subImg, childImg, subChildImg } = item;
                                                                                            return (
                                                                                                <div key={Id}>
                                                                                                    {
                                                                                                        Description && (
                                                                                                            <div className="Content d-flex align-items-center py-2 mb-2" key={Id}>
                                                                                                                {
                                                                                                                    Img && (
                                                                                                                        <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${Img}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                                <p>{Description}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        subDescription && (
                                                                                                            <div className="Content d-flex align-items-center py-2 mb-2" key={Id}>
                                                                                                                {
                                                                                                                    subImg && (
                                                                                                                        <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${subImg}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                                <p>{subDescription}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        childDescription && (
                                                                                                            <div className="Content d-flex align-items-center py-2 mb-2" key={Id}>
                                                                                                                {
                                                                                                                    childImg && (
                                                                                                                        <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${childImg}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                                <p>{childDescription}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        subChildDescription && (
                                                                                                            <div className="Content d-flex align-items-center py-2 mb-2" key={Id}>
                                                                                                                {
                                                                                                                    subChildImg && (
                                                                                                                        <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${subChildImg}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                                <p>{subChildDescription}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>)
                                                    }
                                                    {
                                                        HeadingContent && (
                                                            <div className='NavTabHeadingSection'>
                                                                {
                                                                    HeadingContent.map((item) => {
                                                                        const { Id, gridClass, Heading, Description } = item;
                                                                        return (
                                                                            <div key={Id} className='row justify-content-center mt-3'>
                                                                                {
                                                                                    Heading && (
                                                                                        <div className={gridClass}>
                                                                                            <h3 className='Heading'>{Heading}</h3>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    Description && (
                                                                                        <div className={gridClass}>
                                                                                            <p>{Description}</p>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    <div className={childClassName}>
                                                        {
                                                            Data.map((item) => {
                                                                const { Id, Img, className, cardName, headingClassName, Title, Description } = item;
                                                                return (
                                                                    <div className={className} key={Id}>
                                                                        <div className={cardName}>
                                                                            <div className={`cardImg text-start d-flex align-items-center w-100 ${Title ? 'justify-content-between' : 'justify-content-center'}`}>
                                                                                {
                                                                                    Title && (
                                                                                        <p className={headingClassName}>{Title}</p>
                                                                                    )
                                                                                }
                                                                                <img className='mt-2' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                            </div>
                                                                            <div className="card-body px-1 d-flex flex-column text-start justify-content-center">
                                                                                <p className="Description m-0" dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                                {
                                    DataToggle && (
                                        <>
                                            <div className="NavTabsSections pt-0">
                                                <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                                                    {
                                                        DataToggle.map((item) => {
                                                            const { Id, ActiveName, IdName, ToggleId, ariaSelected, Heading, Img } = item;
                                                            return (
                                                                <li key={Id} className={`nav-item ${ActiveName}`} id={IdName} data-bs-toggle="pill" data-bs-target={`#${ToggleId}`} type="button" role="tab" aria-controls={ToggleId} aria-selected={ariaSelected} >
                                                                    <img src={require(`../../image/HCM Coe/${Img}`)} alt="" />
                                                                    <p className="BtnName">{Heading}</p>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div className="tab-content" id="CardsContent">
                                                    {
                                                        DataToggle.map((item) => {
                                                            const { Id, ToggleId, IdName, ActiveName, Data } = item;
                                                            return (
                                                                <div key={Id} className={`tab-pane fade ${ActiveName}`} id={ToggleId} role="tabpanel" aria-labelledby={IdName}>
                                                                    {
                                                                        Data.map((item) => {
                                                                            const { Id, className, HeadingCardClassName, Heading, Description, subDescription, subHeading, subHeadingDescription, CardData, Content, Staffing } = item;
                                                                            return (
                                                                                <div className={`${className} text-center d-flex flex-column align-items-center mt-5`} key={Id}>


                                                                                    <h2 className='Heading mb-4'>{Heading}</h2>
                                                                                    <p className="Description mb-4">{Description}</p>
                                                                                    {
                                                                                        subHeading && (
                                                                                            <h4 className='SubDescription fw-bold'>{subHeading}</h4>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        subDescription && (
                                                                                            <p className='HeadingSubDescription mb-4'>{subDescription}</p>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        subHeadingDescription && (
                                                                                            <h4 className='SubDescription mb-4 fw-bold'>{subHeadingDescription}</h4>
                                                                                        )
                                                                                    }
                                                                                    <div className={`${HeadingCardClassName}`}>
                                                                                        {
                                                                                            CardData.map((item) => {
                                                                                                const { Id, className, subClassName, topHeading, topDescripton, Heading, Description, FullDesc, subFullDesc, ContentDesc, subDescription, secSubDescription, subChildDescription, btnName, btnLink, Img,
                                                                                                    DescriptionImg, subDescriptionImg, secSubDescriptionImg, subChildDescriptionImg, imgGrid, cardGrid, cardClass } = item;
                                                                                                return (
                                                                                                    className !== "" ? (<div className={`${className}`} key={Id}>

                                                                                                        <div className={`${subClassName}`}>
                                                                                                            <div className={imgGrid}>
                                                                                                                <img className='' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                                                            </div>
                                                                                                            <div className={cardGrid}>
                                                                                                                <div className={`card-body ${cardClass}`}>
                                                                                                                    <h2 className='CardHeading mb-3'>{Heading}</h2>
                                                                                                                    {
                                                                                                                        FullDesc && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                <p className='CardDes'>{FullDesc}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        Description && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                <p className='CardDes'>{Description}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    <div className='SubDesc'>
                                                                                                                        {
                                                                                                                            btnName && (
                                                                                                                                <div className='BtnSection'>
                                                                                                                                    <Link to={btnLink} className='btn btn-dark'>{btnName}
                                                                                                                                        <i className="fas fa-angle-right"></i>
                                                                                                                                    </Link>

                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                    </div>) : (
                                                                                                        <div className={`${subClassName}`}>
                                                                                                            {
                                                                                                                topHeading && (
                                                                                                                    <div className="TopHeadingSection row justify-content-center mb-4 text-center">
                                                                                                                        <div className="col-md-5 mb-3">
                                                                                                                            <h4>{topHeading}</h4>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-10">{topDescripton}</div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                            <div className={imgGrid}>
                                                                                                                <img className='' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                                                            </div>
                                                                                                            <div className={cardGrid}>
                                                                                                                <div className={`card-body ${cardClass ? cardClass : ""}`}>
                                                                                                                    <h2 className='CardHeading mb-3'>{Heading}</h2>
                                                                                                                    {
                                                                                                                        FullDesc && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                <p className='CardDes'>{FullDesc}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        subFullDesc && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                <p className='CardDes'>{subFullDesc}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        Description && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                {
                                                                                                                                    DescriptionImg && (
                                                                                                                                        <p className='CardSideIcon'>
                                                                                                                                            <img className='pe-2' src={require(`../../image/CloudTransformation/CardIcon/${DescriptionImg}`)} alt="" />
                                                                                                                                        </p>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                <p className='CardDes' dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    <div className='SubDesc'>
                                                                                                                        {
                                                                                                                            subDescription && (
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        subDescription && (
                                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                                {subDescriptionImg && (
                                                                                                                                                    <p className='CardSideIcon'><img className='pe-2' src={require(`../../image/CloudTransformation/CardIcon/${subDescriptionImg}`)} alt="" /> </p>
                                                                                                                                                )}
                                                                                                                                                <p className='CardDescription'>{subDescription}</p>
                                                                                                                                            </div>
                                                                                                                                        )

                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        secSubDescription && (
                                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                                {secSubDescriptionImg && (
                                                                                                                                                    <p className='CardSideIcon'><img className='pe-2' src={require(`../../image/CloudTransformation/CardIcon/${secSubDescriptionImg}`)} alt="" /> </p>
                                                                                                                                                )}
                                                                                                                                                <p className='CardDescription' >{secSubDescription}</p>
                                                                                                                                            </div>
                                                                                                                                        )

                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        subChildDescription && (
                                                                                                                                            <div className="d-flex mb-3 align-items-center mb-3 align-items-center">
                                                                                                                                                {subChildDescriptionImg && (
                                                                                                                                                    <p className='CardSideIcon'><img className='pe-2' src={require(`../../image/CloudTransformation/CardIcon/${subChildDescriptionImg}`)} alt="" /> </p>
                                                                                                                                                )}
                                                                                                                                                <p className='CardDescription' dangerouslySetInnerHTML={{ __html: subChildDescription }}></p>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        ContentDesc && (
                                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                                <p className='ContentDesc'>{ContentDesc}</p>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    }

                                                                                                                                </>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        {
                                                                                                                            btnName && (
                                                                                                                                <div className='BtnSection'>
                                                                                                                                    <Link to={btnLink} className='btn btn-dark'>{btnName}
                                                                                                                                        <i className="fas fa-angle-right"></i>
                                                                                                                                    </Link>

                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        Content && (
                                                                                            Content.map((item) => {
                                                                                                const { Id, className, parentClassName, childClassName, Title, Description, Data } = item;
                                                                                                return (
                                                                                                    <div className={className} key={Id}>
                                                                                                        <div className={parentClassName}>
                                                                                                            <div className="col-md-8">
                                                                                                                <h3 className="Heading mb-3 font-weight-bold">{Title}
                                                                                                                </h3>
                                                                                                            </div>

                                                                                                            <div className="col-md-10 mb-5">
                                                                                                                <p className="cardDescription">{Description}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className={childClassName}>
                                                                                                            {Data.map((item) => {
                                                                                                                const { Id, Img, className, headingClassName, Title, Description, cardName } = item;
                                                                                                                return (
                                                                                                                    <div className={className} key={Id}>
                                                                                                                        <div className={cardName}>
                                                                                                                            <div className="cardImg text-start d-flex justify-content-between align-items-center w-100">
                                                                                                                                <h5 className={headingClassName}>{Title}</h5>
                                                                                                                                <img className='ImgContent' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                                                                            </div>
                                                                                                                            <div className='card-body px-1 d-flex flex-column text-start justify-content-center'>
                                                                                                                                <p className="cardDescription">{Description}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })}

                                                                                                        </div>
                                                                                                        <div className={childClassName}>
                                                                                                            <div className="ImgSection">
                                                                                                                {
                                                                                                                    HeadingImg && (
                                                                                                                        <img src={require(`../../image/CloudTransformation/${HeadingImg}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })

                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        Staffing && (
                                                                                            Staffing.map((item) => {
                                                                                                const { Id, className, subClassName, classSection, secClassSection, Heading, HeadingImg, Description, Data } = item;
                                                                                                return (
                                                                                                    <div className={className} key={Id}>
                                                                                                        <div className={subClassName}>
                                                                                                            <div className={classSection}>
                                                                                                                <div className="col-md-12 mb-3">
                                                                                                                    <h4>{Heading}</h4>
                                                                                                                </div>
                                                                                                                <div className="col-md-4 mt-3 mb-3">
                                                                                                                    <img className='ImgSection' src={require(`../../image/CloudTransformation/${HeadingImg}`)} alt="" />
                                                                                                                </div>
                                                                                                                <div className="col-md-11 mb-3">
                                                                                                                    <p className='CardDescription'>{Description}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className={secClassSection}>
                                                                                                                {
                                                                                                                    Data.map((item) => {
                                                                                                                        const { Id, className, gridClass, Heading, Img, Btn, Url } = item;
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    Heading && (<div className={gridClass}>
                                                                                                                                        <div className={className} key={Id}>
                                                                                                                                            <div className="CardImg mt-4">
                                                                                                                                                {Img && (
                                                                                                                                                    <img src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                                                                                                )}
                                                                                                                                            </div>
                                                                                                                                            <div className="card-body">
                                                                                                                                                <p className='CardDescription'>{Heading}</p>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    className === "BtnClass" && (
                                                                                                                                        <div className={gridClass}>
                                                                                                                                            <div className="SubDesc mt-4">
                                                                                                                                                <div className="CardBtn">
                                                                                                                                                    <Link to={Url} className="btn btn-dark">{Btn}<i className="fas fa-angle-right"></i></Link>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                                {
                                    headingClassName === "ProjectServiceSection" && (
                                        <div className={className}>
                                            <div className={parentClassName}>
                                                <div className="col-md-8">
                                                    <h3 className="Heading mb-3  font-weight-bold">{Heading}
                                                    </h3>
                                                </div>

                                                <div className="col-md-11 mb-5">
                                                    <p className="CardDescription">{Description}</p>
                                                </div>
                                            </div>
                                            <div className={subClassName}>
                                                {Data.map((item) => {
                                                    const { Id, Img, className, Title, Description } = item;
                                                    return (
                                                        <div className={className} key={Id}>
                                                            <div>
                                                                {
                                                                    Img && (
                                                                        <img className='ImgContent' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='HeadingSection ms-3 d-flex align-items-center flex-column'>
                                                                {
                                                                    Title && (
                                                                        <h5 className='d-block w-100 text-start m-0'>{Title}</h5>
                                                                    )
                                                                }
                                                                <p className="line-_height m-0" dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>

                                            <div className={childClassName}>
                                                <div className="ImgSection">
                                                    {
                                                        HeadingImg && (
                                                            <img src={require(`../../image/CloudTransformation/${HeadingImg}`)} alt="" />
                                                        )
                                                    }
                                                </div>

                                            </div>
                                            <div className="bottom-text text-center mt-4">
                                                <p>{bottomText}</p>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    headingClassName === "MoreInfoSection" && (
                                        <div className={className}>
                                            <div className={parentClassName}>
                                                <p className="InfoSectionDescription">{Description}</p>
                                                <Link to={Url} className='btn btn-dark HoverBtn'>
                                                    {Btn}
                                                    <GoChevronRight />
                                                </Link>

                                            </div>
                                            <div className={subClassName}>
                                                <div className="ImgSection">
                                                    <img src={require(`../../image/CloudTransformation/${HeadingImg}`)} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        )
                    })
                }
            </div >
            <FooterBrands />
            <GetStarted />
        </>
    )
}
