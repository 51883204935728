import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import SlidData from '../data/About.json'

export default () => {
    const [activeSlide, setactiveSlide] = useState(2);

    const next = () =>
        activeSlide < SlidData[1].Data.length - 1 && setactiveSlide(activeSlide + 1);

    const prev = () => activeSlide > 0 && setactiveSlide(activeSlide - 1);

    const getStyles = (index) => {
        if (activeSlide === index)
            return {
                opacity: 1,
                transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
                zIndex: 10
            };
        else if (activeSlide - 1 === index)
            return {
                opacity: 1,
                transform: "translateX(-240px) translateZ(-400px) rotateY(35deg)",
                zIndex: 9
            };
        else if (activeSlide + 1 === index)
            return {
                opacity: 1,
                transform: "translateX(240px) translateZ(-400px) rotateY(-35deg)",
                zIndex: 9
            };
        else if (activeSlide - 2 === index)
            return {
                opacity: 1,
                transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
                zIndex: 8
            };
        else if (activeSlide + 2 === index)
            return {
                opacity: 1,
                transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
                zIndex: 8
            };
        else if (index < activeSlide - 2)
            return {
                opacity: 0,
                transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
                zIndex: 7
            };
        else if (index > activeSlide + 2)
            return {
                opacity: 0,
                transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
                zIndex: 7
            };
    };

    return (
        <>
            {/* carousel */}
            <div className="slideC">
                {
                    SlidData.map((item) => {
                        const { Id, Data } = item;
                        return (
                            <>{
                                Id === 2 && (Data.map((item, i) => (
                                    <React.Fragment key={item.id}>
                                        <div
                                            className="slide"
                                            style={{
                                                background: item.bgColor,
                                                boxShadow: `0 5px 20px ${item.bgColor}30`,
                                                ...getStyles(i)
                                            }}
                                        >
                                            <SliderContent {...item} />
                                        </div>
                                        <div
                                            className="reflection"
                                            style={{
                                                background: `linear-gradient(to bottom, ${item.bgColor}40, transparent)`,
                                                ...getStyles(i)
                                            }}
                                        />
                                    </React.Fragment>
                                ))
                                )
                            }

                            </>
                        )
                    })
                }
            </div>
            {/* carousel */}

            <div className="btns">
                <FontAwesomeIcon
                    className="btn"
                    onClick={prev}
                    icon={faChevronLeft}
                    color="#fff"
                    size="2x"
                />
                <FontAwesomeIcon
                    className="btn"
                    onClick={next}
                    icon={faChevronRight}
                    color="#fff"
                    size="2x"
                />
            </div>
        </>
    );
};

const SliderContent = (props) => {
    const { Id, Name, Img } = props;
    return (
        <div className="sliderContent" key={Id}>
            <div className='CardMain p-3 col-md-4'>
                <img src={require(`../image/About/${Img}`)} className="card-img-top p-2" alt="..." />
                <div className="card-body">
                    <p className="card-text">{Name}</p>
                </div>
            </div>
        </div>
    );
};
