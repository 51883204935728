import React, { useEffect, useRef, useState } from 'react'
import img from '../image/PageNotFound/galaxy-background.jpg'
import Jupiter from '../image/PageNotFound/Jupiter.png'
import sun from '../image/PageNotFound/planet2.png'
import planet1 from '../image/PageNotFound/planet1.png'
import astronot from '../image/PageNotFound/astronot.png'

export const PageNotFoundError = () => {

    const [stars, setStars] = useState([]);

    useEffect(() => {
        const starCount = 100; // Adjust the number of stars here
        const generatedStars = Array.from({ length: starCount }).map((_, index) => ({
            id: index,
            left: Math.random() * 100, // Random left position (0-100%)
            top: Math.random() * 100, // Random top position (0-100%)
            duration: Math.random() * 3 + 2, // Random duration (between 2s to 5s)
        }));

        setStars(generatedStars);
    }, []);

    // const astronautRef = useRef(null);
    // const moveSpeed = 0.01; // Speed factor for the astronaut's movement
    // const targetPosition = useRef({ x: 0, y: 0 }); // Target position
    // const currentPosition = useRef({ x: 0, y: 0 }); // Current position
    // const animationFrameId = useRef(null); // To store the requestAnimationFrame ID

    // const moveAstronaut = () => {
    //     const astronaut = astronautRef.current;
    //     const windowWidth = window.innerWidth;
    //     const windowHeight = window.innerHeight;

    //     // Generate random positions within the window bounds
    //     targetPosition.current.x = Math.random() * (windowWidth - 100); // 100 is the width of the astronaut image
    //     targetPosition.current.y = Math.random() * (windowHeight - 100); // 100 is the height of the astronaut image

    //     // Function to animate the movement
    //     const animateMovement = () => {
    //         // Calculate the difference between current and target positions
    //         const dx = targetPosition.current.x - currentPosition.current.x;
    //         const dy = targetPosition.current.y - currentPosition.current.y;

    //         // Move towards the target position
    //         currentPosition.current.x += dx * moveSpeed;
    //         currentPosition.current.y += dy * moveSpeed;

    //         // Apply new position
    //         astronaut.style.left = `${currentPosition.current.x}px`;
    //         astronaut.style.top = `${currentPosition.current.y}px`;

    //         // Check if the astronaut is close to the target position to stop the animation
    //         if (Math.abs(dx) > 1 || Math.abs(dy) > 1) {
    //             animationFrameId.current = requestAnimationFrame(animateMovement); // Continue the animation
    //         } else {
    //             // Gradually scale down the astronaut if desired
    //             astronaut.style.transform = 'scale(1)'; // Reset scale on stop
    //             setTimeout(moveAstronaut, 2000); // Wait before moving to a new target
    //         }
    //     };

    //     // Start the animation loop
    //     animateMovement();
    // };

    // useEffect(() => {
    //     moveAstronaut(); // Initial call to start moving the astronaut

    //     return () => {
    //         cancelAnimationFrame(animationFrameId.current); // Cleanup on unmount
    //     };
    // }, []);



    return (
        <>
            <div className="PageNotFound">
                <img className='galaxyBG' src={img} alt="" />
                <ul className="starBlink">
                    {stars.map((star) => (
                        <li
                            key={star.id}
                            style={{
                                left: `${star.left}vw`,
                                top: `${star.top}vh`,
                                animationDuration: `${star.duration}s`,
                            }}
                        />
                    ))}
                </ul>
                <section className="MainContent p-3 h-100">
                    <div className="NotFound h-100 d-flex flex-column justify-content-center align-items-center">
                        <h1 className='BigText m-0'>404</h1>
                        <h3 className='fw-bolder text-white mb-3'>Uh oh, Notting here</h3>
                        <button className='btn HoverBtn'>Back To Home</button>
                    </div>
                </section>
                <img className='movingPlanet' src={Jupiter} alt="Moving Planet" />
                <img className='planet2' src={sun} alt="Moving Planet" />
                <img className='planet1' src={planet1} alt="Moving Planet" />
                <img className='astronaut' src={astronot} alt="Astronaut" />
            </div>
        </>
    )
}
