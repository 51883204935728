import React from 'react'
import { Banner } from '../Banner'
import Agile from './Agile'
import { GetStarted } from '../FooterSection/GetStarted'

export const Home = () => {
    return (
        <div>
            <Banner />
            <Agile />
            <GetStarted />
        </div>
    )
}
