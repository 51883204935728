import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import EDT_Data from '../../data/EDT/EDT_COE.json'
import { Banner } from '../Banner';
import '../../style/EDT.css'
import { AccordionButton } from 'react-bootstrap';
import { GoHorizontalRule, GoPlus } from 'react-icons/go';
import TrustedOrganization from '../TrustedOrganization';
import { GetStarted } from '../FooterSection/GetStarted';
import FooterBrands from '../FooterBrands';
import { Helmet } from 'react-helmet';


const EDT_COE = () => {

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };


    return (
        <>
            <Helmet>
                <title>Enterprise Digital Transformation COE Services | Phenom eCloud</title>
                <meta name="description" content="Phenom eCloud's Enterprise Digital Transformation Center of Excellence (COE) offers cutting-edge strategies, innovative technologies, and comprehensive support to help businesses enhance efficiency, drive growth, and navigate the digital landscape with success." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {EDT_Data.map((item, index) => {
                const { id, title, desc, bannerImage, accordians, infotext, grid, sub_title, card } = item;
                return (
                    <div className="" key={id}>
                        {id == 1 ? (
                            <div class="row d-flex justify-content-around py-4  mb-3 align-items-center   whitebg_section discover_possible">

                                <div className="col-md-4 text-center col-sm-12 col-12">
                                    <div>
                                        <img src={require(`../../image/AdvisoryService/${bannerImage}.png`)} className="img-fluid " />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-12 col-12">
                                    {
                                        desc.map((data, index) => (
                                            <div className="head_on_desc_body mb-3">
                                                {data.Desc_header && <p className="mb-3" style={{ fontWeight: 'bolder', lineHeight: '20px', fontSize: '27px' }}>{data.Desc_header}</p>}
                                                {data.Desc &&
                                                    data.Desc.map((value, index1) => (

                                                        <div className="explore_service_section">
                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                <div>
                                                                    <p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '15px' }}>{value.text} </p>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        ) : ''}

                        {
                            (id == 3 || id == 5) && (
                                <div className="row d-flex justify-content-center align-items-center allcoe_service_card_bg">
                                    {title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{title}</h3>}
                                    {
                                        desc.map((value, items) => (
                                            value && <p key={items} className="text-center service_desc" style={{ width: '50%', fontSize: '14px' }}>{value.text}</p>
                                        ))
                                    }

                                    <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                                        {
                                            card.map((data, index) => {

                                                if (data.card_name == 'What Sets Us Apart') {
                                                    return <div className="col-md-6 col-sm-12 m-3 our_service_card unique_card card d-flex  align-items-center   justify-content-evenly ">
                                                        <div className=" col-md-12 d-flex  flex-row align-items-center   justify-content-evenly ">
                                                            <div className='col-md-8 col-sm-12'>
                                                                <p className='card_header fw-bold'>{data.card_name}</p>
                                                                {
                                                                    data.card_desc.map((value, index1) => (
                                                                        <div className=" d-flex flex-column line-_height mb-2 justify-content-evenly">
                                                                            <span className='title'><strong>{value.title}</strong> {value.desc}</span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className='col-md-4 col-sm-12 text-center'>
                                                                <img src={require(`../../image/AdvisoryService/${data.icon}`)} alt="" style={{ height: '135px' }} srcset="" />
                                                            </div>
                                                        </div>
                                                        <div className='e_book_section'>
                                                            <p className='fw-bold line-_height'>{data.card_btn_header}</p>
                                                            <Link to={data.card_url}>
                                                                <button className='btn HoverBtn button-hover overlaybtn text-white normal-text-font last_card'>{data.card_btn}</button>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                }
                                                else {
                                                    return <div className={`col-md-3  our_service_card col-dm-12 m-3 card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize edt-card-fix-height ${data.card_class}`}>

                                                        <div className='cardImg text-start d-flex justify-content-between align-items-center'>

                                                            <p className="fw-bold">{data.card_name}</p>
                                                            <img src={require(`../../image/AdvisoryService/${data.icon}`)} alt="" srcset="" className='icon-box' />
                                                        </div>
                                                        <div className="card-body px-1 d-flex">

                                                            <p className="our_service_card_desc">{data.card_desc}</p>
                                                        </div>
                                                        {
                                                            data.card_url && (
                                                                <div className=" col-md-12 d-flex justify-content-end align-items-center">
                                                                    <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                        <p >{data.card_btn}</p></Link>
                                                                    <Link to={data.card_url} >
                                                                        <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                }


                                            })
                                        }


                                    </div>

                                </div>
                            )
                        }

                        {id === 4 && (

                            <div className="row d-flex justify-content-around mt-3 mb-3">
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                    <div className=" ">
                                        <div className="">
                                            <p className="text-center fw-bold" style={{ fontSize: '25px' }}>{title}</p>

                                            {
                                                desc.map((data, id) => (
                                                    <p className='line-_height text-center'>{data.text}</p>
                                                ))
                                            }
                                        </div>
                                        <div className="">
                                            <div className="row d-flex justify-content-center whitebg_section edt_digital_transform Advisory_service">

                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  " style={{ padding: '16px' }}>
                                                    <Accordion className='d-flex flex-column EcCoe'>
                                                        {accordians.map((item, index) => {
                                                            const { header, desc } = item;
                                                            return (
                                                                <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                    <AccordionButton className='Dropdown-Bg-Color'
                                                                        onClick={() => handleToggle(index.toString())}
                                                                        aria-expanded={expandedItem === index.toString()}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                            <span className='fw-bolder sm-font-size'>{header}</span>
                                                                            {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                        </div>
                                                                    </AccordionButton>

                                                                    <Accordion.Body key={index} className='py-1'>
                                                                        <p className='text-start'>{desc}</p>
                                                                    </Accordion.Body>

                                                                </Accordion.Item>
                                                            );
                                                        })}
                                                    </Accordion>



                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
                                                    <img src={require(`../../image/AdvisoryService/${bannerImage}.png`)} className=" accordian_img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            <FooterBrands />
            <GetStarted />
        </>
    )
}

export default EDT_COE