import React from 'react'
import { Banner } from '../Banner'
import TrustedOrganization from '../TrustedOrganization'
import img from '../../image/ERP/ERP COE/ERP projects.png'
import { Link } from 'react-router-dom'
import { GetStarted } from '../FooterSection/GetStarted'
import FooterBrands from '../FooterBrands'
import { Helmet } from 'react-helmet';

export const ERP_COE = ({ adata }) => {
    const ERPCOE = adata
    return (
        <>
           <Helmet>
                <title>Enterprise Resource Planning COE Services | Phenom eCloud</title>
                <meta name="description" content="Phenom eCloud's ERP Center of Excellence (COE) provides expert advisory, implementation, data management, solution engineering, integrations, and analytics to optimize ERP systems for increased operational efficiency across industries." />
            </Helmet>
            <Banner />
            <TrustedOrganization />

            <div className="ErpCoe">
                {
                    ERPCOE.map((item, index) => {
                        const { className, HeadingData, CardSection } = item;
                        return (
                            className !== "FlexContentBody" && className !== "OurServices" && className !== "FlexContent" && (
                                <div className={className} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-center align-items-center row px-4 padding_remove">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className} dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    }
                                </div>
                            )
                        )
                    })
                }
                {
                    ERPCOE.map((item, index) => {
                        const { className, ContentData } = item;
                        return (
                            className === "FlexContentBody" && (
                                <div className={`${className} p-5 py-0`} key={index}>
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { className, Grid } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    {
                                                        Grid.map((item, index) => {
                                                            const { className, TextData } = item;
                                                            return (
                                                                <div className={className} key={index}>
                                                                    {
                                                                        TextData.map((item, index) => {
                                                                            const { className, Name, BtnName, Content, iconImg, Img, Url } = item;
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {
                                                                                        Content ? (
                                                                                            <div className="d-flex align-items-center mb-3" key={index}>
                                                                                                {
                                                                                                    iconImg && (
                                                                                                        <div className="ImgSection">
                                                                                                            <img className='small-icon me-3' src={require(`../../image/ERP/ERP COE/${iconImg}`)} alt="" />
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                {
                                                                                                    Name && (
                                                                                                        <p className={className}>{Name}</p>
                                                                                                    )
                                                                                                }
                                                                                                {Url && (
                                                                                                    <Link to={Url} className={className}>{BtnName}</Link>
                                                                                                )}
                                                                                            </div>
                                                                                        ) : (
                                                                                            Img && (
                                                                                                <div className="d-flex justify-content-center">
                                                                                                    <img className='img-size' src={require(`../../image/ERP/ERP COE/${Img}`)} alt="" />
                                                                                                </div>)
                                                                                        )
                                                                                    }

                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }))
                                    }
                                </div>
                            )
                        )
                    })
                }
                {
                    ERPCOE.map((item, index) => {
                        const { className, HeadingData, CardSection } = item;
                        return (
                            className === "OurServices" && (
                                <div className={`${className}`} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid, CardSection } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-center align-items-center row px-4 padding_remove">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className} dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            CardSection && (
                                                                CardSection.map((item, index) => {
                                                                    const { className, CardData } = item;
                                                                    return (
                                                                        <div className={className} key={index}>
                                                                            <div className="card-section-content row px-5 justify-content-center text-center padding_remove">
                                                                                {
                                                                                    CardData.map((item, index) => {
                                                                                        const { GridName, className, ImgName, Heading, Name, UrlName, Url } = item;
                                                                                        return (
                                                                                            <div className={GridName} key={index}>
                                                                                                <div className={className}>
                                                                                                    <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                                                                        {
                                                                                                            Heading && (
                                                                                                                <p className='fw-bolder card-heading-size'>{Heading}</p>
                                                                                                            )
                                                                                                        }
                                                                                                        {ImgName && (
                                                                                                            <img className='icon-size icon-box' src={require(`../../image/ERP/ERP COE/${ImgName}`)} alt="" />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="card-body px-1 d-flex flex-column text-start justify-content-center">
                                                                                                        <p className="Description text-start m-0">{Name}</p>
                                                                                                    </div>
                                                                                                    {
                                                                                                        Url && (
                                                                                                            <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                                                                <Link to={Url} className='text-start text-decoration-none UrlText px-2' href="">{UrlName}</Link>
                                                                                                                <Link className='btn' to={Url}><i className="fa-solid fa-arrow-right px-2"></i></Link>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )

                                                        }
                                                    </div>
                                                )
                                            })
                                        )
                                    }

                                </div>
                            )
                        )
                    })
                }
                {
                    ERPCOE.map((item, index) => {
                        const { className, ContentData, CardSection } = item;
                        return (
                            className === "FlexContent" && (
                                <div className={className} key={index}>
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { containerClass, className, Grid, CardSection } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    <div className={`${containerClass ? containerClass : ''}`}>
                                                        {
                                                            Grid.map((item, index) => {
                                                                const { className, TextData } = item;
                                                                return (
                                                                    <div className={`${className}`} key={index}>
                                                                        {
                                                                            TextData.map((item, index) => {
                                                                                const { HeadinClass, className, Name, BtnName, iconImg, Img, BtnUrl, DesctiptionContent } = item;
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        {
                                                                                            HeadinClass ? (
                                                                                                <div className={`${HeadinClass ? HeadinClass : ''}`}>
                                                                                                    {
                                                                                                        iconImg && (
                                                                                                            <div className="ImgSection">
                                                                                                                <img className='small-icon me-2' src={require(`../../image/ERP/ERP COE/${iconImg}`)} alt="" />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        Name && (
                                                                                                            <p className={className}>{Name}</p>
                                                                                                        )
                                                                                                    }
                                                                                                    {BtnUrl && (
                                                                                                        <Link to={BtnUrl} className={className}>{BtnName}</Link>
                                                                                                    )}
                                                                                                </div>
                                                                                            ) : (

                                                                                                <div className={`${className}`}>

                                                                                                    {
                                                                                                        DesctiptionContent && (
                                                                                                            DesctiptionContent.map((item, index) => {
                                                                                                                const { HeadinClass, className, Name, iconImg } = item;
                                                                                                                return (
                                                                                                                    <React.Fragment key={index}>
                                                                                                                        {
                                                                                                                            <div className="ImgSection d-flex mb-3 align-items-center">
                                                                                                                                {iconImg && (
                                                                                                                                    <img className='small-icon me-2' src={require(`../../image/ERP/ERP COE/${iconImg}`)} alt="" />
                                                                                                                                )}
                                                                                                                                <p className={`${className} m-0`}>{Name}</p>

                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </React.Fragment>
                                                                                                                )
                                                                                                            })
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        Img && (
                                                                                                            <div className="">
                                                                                                                <img className='img-size' src={require(`../../image/ERP/ERP COE/${Img}`)} alt="" />
                                                                                                            </div>)
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            CardSection && (
                                                                CardSection.map((item, index) => {
                                                                    const { className, CardData } = item;
                                                                    return (
                                                                        <div className={className} key={index}>
                                                                            <div className="card-section-content row pt-5 justify-content-center text-center padding_remove">
                                                                                {
                                                                                    CardData.map((item, index) => {
                                                                                        const { GridName, className, ImgName, Heading, Name, UrlName, Url } = item;
                                                                                        return (
                                                                                            <div className={GridName} key={index}>
                                                                                                <div className={className}>
                                                                                                    <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                                                                        {
                                                                                                            Heading && (
                                                                                                                <p className='fw-bolder card-heading-size'>{Heading}</p>
                                                                                                            )
                                                                                                        }
                                                                                                        {ImgName && (
                                                                                                            <img className='icon-size icon-box' src={require(`../../image/ERP/ERP COE/${ImgName}`)} alt="" />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="card-body px-1 d-flex flex-column text-start justify-content-center">
                                                                                                        <p className="Description text-start m-0">{Name}</p>
                                                                                                    </div>
                                                                                                    {
                                                                                                        Url && (
                                                                                                            <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                                                                <Link to={Url} className='text-start text-decoration-none UrlText px-2' href="">{UrlName}</Link>
                                                                                                                <Link className='btn' to={Url}><i className="fa-solid fa-arrow-right px-2"></i></Link>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )

                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }))
                                    }

                                </div>
                            )
                        )
                    })
                }
            </div>
            <FooterBrands />
            <GetStarted />
        </>
    )
}
